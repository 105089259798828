<template>
  <button
    v-bind="[$attrs, $props]"
    v-on="$listeners"
    :disabled="isDisabled"
    :class="[
      'mx-button',
      `mx-button--${variant}`,
      `mx-button--${size}`,
      `mx-button--${behavior}`,
      { 'mx-button--loading': loading },
      { 'mx-button--disabled-only': loading && !disabled },
      { 'tlw-w-full': fullWidth },
    ]"
  >
    <div class="mx-button__content-wrapper">
      <div v-if="loading" class="spinner-loader"></div>
      <i v-else-if="icon" :class="icon" class="mx-button__icon" />

      <span v-if="$slots.default" class="mx-button__text">
        <slot />
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'MXButton',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: variant => {
        return ['primary', 'secondary', 'tertiary', 'danger-primary', 'danger-secondary', 'danger-tertiary'].includes(
          variant
        );
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator: size => {
        return ['small', 'medium', 'large'].includes(size);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    behavior: {
      type: String,
      default: 'default',
      validator: behavior => {
        return ['default', 'block'].includes(behavior);
      },
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabled() {
      return this.disabled || this.loading;
    },
  },
};
</script>

<style lang="scss" scoped>
$sizes: (
  small: (
    font-size: 0.875rem,
    padding: 4px 12px,
  ),
  medium: (
    font-size: 0.875rem,
    padding: 8px 16px,
  ),
  large: (
    font-size: 1rem,
    padding: 12px 24px,
  ),
);

.mx-button {
  align-items: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  line-height: 150%;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: top;
  height: fit-content;
}

.mx-button--primary {
  background: #7427f1;
  color: #fff;
  border: 2px solid #7427f1;

  &:hover {
    background: #4d1aa1;
  }

  &:focus {
    background: #4d1aa1;
    outline: 2px solid #7427f1;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    background: #cfcfcf;
    color: #6f6f6f;
    border: 2px solid #cfcfcf;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-button--secondary {
  background: transparent;
  color: #7427f1;
  border: 2px solid #7427f1;
  border-radius: 4px;

  .mx-button__text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    & > i {
      font-size: 1rem;
    }
  }

  &:hover {
    background: #ead3fe;
  }

  &:focus {
    background: #ead3fe;
    outline: 2px solid #7427f1;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    color: #6f6f6f;
    border: 2px solid #cfcfcf;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-button--tertiary {
  background: transparent;
  color: #7427f1;

  .mx-button__text {
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    color: #4d1aa1;
  }

  &:focus {
    color: #4d1aa1;
    outline: none;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    color: #6f6f6f;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-button--danger-primary {
  background: #db3939;
  color: #fff;
  border: 2px solid #db3939;

  &:hover {
    background: #a62b2b;
  }

  &:focus {
    background: #802121;
    outline: 2px solid #db3939;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    color: #6f6f6f;
    border: 2px solid #cfcfcf;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-button--danger-secondary {
  background: transparent;
  color: #db3939;
  border: 2px solid #db3939;

  &:hover {
    background: #feb4b4;
    color: #802121;
    border: 2px solid #802121;
  }

  &:focus {
    background: transparent;
    color: #802121;
    border: 2px solid #802121;
  }

  &:disabled {
    pointer-events: none;
    color: #6f6f6f;
    border: 2px solid #cfcfcf;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-button--danger-tertiary {
  background: transparent;
  color: #db3939;

  > div > span {
    text-decoration: underline;
  }

  &:hover {
    color: #802121;
  }

  &:focus {
    color: #802121;
  }
}

@each $size, $config in $sizes {
  .mx-button--#{$size} {
    font-size: map-get($config, 'font-size');
    padding: map-get($config, 'padding');
  }
}

.mx-button__content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  .mx-button__icon {
    font-size: 1rem;
  }
}

/* Novo spinner personalizado */
.spinner-loader {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid currentColor;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mx-button--block {
  width: 100%;
}

.mx-button--loading {
  cursor: wait;
  pointer-events: none;
}

/* Estilos para manter as cores originais durante o loading */
.mx-button--disabled-only {
  &.mx-button--primary {
    background: #7427f1 !important;
    color: #fff !important;
    border: 2px solid #7427f1 !important;

    & > * {
      color: #fff !important;
    }
  }

  &.mx-button--secondary {
    background: transparent !important;
    color: #7427f1 !important;
    border: 2px solid #7427f1 !important;

    & > * {
      color: #7427f1 !important;
    }
  }

  &.mx-button--tertiary {
    background: transparent !important;
    color: #7427f1 !important;

    & > * {
      color: #7427f1 !important;
    }
  }

  &.mx-button--danger-primary {
    background: #db3939 !important;
    color: #fff !important;
    border: 2px solid #db3939 !important;

    & > * {
      color: #fff !important;
    }
  }

  &.mx-button--danger-secondary {
    background: transparent !important;
    color: #db3939 !important;
    border: 2px solid #db3939 !important;

    & > * {
      color: #db3939 !important;
    }
  }

  &.mx-button--danger-tertiary {
    background: transparent !important;
    color: #db3939 !important;

    & > * {
      color: #db3939 !important;
    }
  }
}

/* Estilos específicos para garantir que o ícone de loading seja visível em botões secundários */
.mx-button--secondary .loading-icon {
  color: #7427f1 !important;
}

.mx-button--secondary:disabled .loading-icon {
  color: #6f6f6f !important;
}
</style>
