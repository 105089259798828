<template>
  <div
    class="banner-preview-container"
    :class="{ 'hover-state': isHovered }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <!-- Banner Card -->
    <div class="banner-preview-card">
      <!-- Left side with drag handle and image -->
      <div class="banner-preview-left">
        <button class="drag-handle" type="button">
          <Icon name="grip-vertical" prefix="fas" />
        </button>
        <div class="banner-image">
          <img
            :src="banner.image_desktop_url || 'https://via.placeholder.com/150x80'"
            :alt="banner.alt_text || 'Banner'"
          />
        </div>
      </div>

      <!-- Center with title and type -->
      <div class="banner-preview-content">
        <h3 class="banner-title">{{ bannerDisplayTitle }}</h3>
        <div class="banner-type">
          <Icon :name="bannerIconName" prefix="fal" class="banner-type-icon" :width="16" :height="16" />
          <span>{{ bannerTypeLabel }}</span>
        </div>
      </div>

      <!-- Right side with actions -->
      <div class="banner-preview-actions">
        <b-dropdown right variant="link" toggle-class="action-button" no-caret>
          <template #button-content>
            <Icon name="ellipsis-h" type="fal" />
          </template>

          <b-dropdown-item @click="$emit('edit', banner)">
            <div class="tlw-flex tlw-items-center">
              <Icon name="pencil" prefix="fal" class="tlw-mr-2" />
              {{ $t('members-area.components.banner-config.preview.actions.edit') }}
            </div>
          </b-dropdown-item>

          <b-dropdown-item variant="danger" @click="$emit('delete', banner)" class="tlw-text-red-500">
            <div class="tlw-flex tlw-items-center">
              <Icon name="trash-alt" prefix="fal" class="tlw-mr-2" />
              {{ $t('members-area.components.banner-config.preview.actions.delete') }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';

export default {
  name: 'BannerPreview',

  components: {
    Icon,
  },

  props: {
    banner: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isHovered: false,
    };
  },

  computed: {
    bannerTypeLabel() {
      return this.banner.kind === 'video' ? 'Vídeo' : 'Imagem/GIF';
    },
    bannerDisplayTitle() {
      return this.banner.title || `Banner ${this.banner.order + 1}`;
    },
    bannerIconName() {
      return this.banner.kind === 'video' ? 'play-circle' : 'image';
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-preview-container {
  position: relative;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 0.75rem;
  width: 100%;
  overflow: visible;

  &.hover-state {
    border-color: #7c3aed;

    .banner-preview-card {
      background-color: #f3ebfa;
    }
  }
}

.banner-preview {
  margin-bottom: 1rem;
}

.banner-preview-card {
  display: flex;
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 12px;
  gap: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  flex-wrap: wrap;

  &:hover {
    .drag-handle {
      opacity: 1;
    }
  }
}

.banner-preview-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.drag-handle {
  color: #262626;
  cursor: grab;
  padding: 4px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  transition: opacity 0.2s ease;

  &:active {
    cursor: grabbing;
  }

  &:hover {
    color: #6b7280;
  }
}

.banner-image {
  width: 120px;
  height: 64px;
  border-radius: 4px;
  overflow: hidden;
  background: #262626;

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.banner-preview-content {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 0; // Prevent text overflow
}

.banner-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.banner-type {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  color: #1e4278;
  font-size: 12px;
  flex-grow: 0;
  border-radius: 100px;
  border: 1px solid #1e4278;
  font-weight: 600;

  .banner-type-icon {
    color: #1e4278;
  }
}

.banner-preview-actions {
  display: flex;
  align-items: center;
  position: relative;
}

/deep/ .action-button {
  @apply tlw-p-2 hover:tlw-text-gray-700 tlw-transition-colors;
  background: none;
  border: none;

  svg {
    color: #262626;
    fill: #262626;
  }

  &::after {
    display: none;
  }
}

/deep/ .dropdown-menu {
  @apply tlw-py-1 tlw-shadow-lg tlw-border tlw-border-gray-200 tlw-rounded-lg;
  min-width: 120px;
  position: absolute;
  z-index: 1000;

  .dropdown-item {
    @apply tlw-flex tlw-items-center tlw-px-4 tlw-py-2 tlw-text-sm;

    &:active {
      background-color: #f3ebfa;
      color: inherit;
    }

    &:hover {
      background-color: #f3ebfa;
      color: inherit;
    }
  }
}

/deep/ .dropdown {
  position: static;
}
</style>
