<template>
  <div class="responsive-modal">
    <div v-if="isOpen" class="responsive-modal__backdrop" @click="close"></div>
    <transition :name="transitionName">
      <div
        v-if="isOpen"
        class="responsive-modal__inner"
        :class="{
          'responsive-modal__inner--bottom-sheet': !isNormalModal,
          'responsive-modal__inner--normal': isNormalModal,
        }"
        @click.stop
      >
        <div class="premium-modal__content">
          <div class="premium-modal__close-wrapper" @click.stop="close">
            <Icon name="close" prefix="fal" :width="24" :height="24" />
          </div>
          <h5>Aproveite mais funcionalidades exclusivas</h5>
          <p>
            Além dos recursos do plano gratuito, agora você também pode explorar o poder completo do SparkFunnels com as
            novas funcionalidades do plano Premium!
          </p>

          <div class="premium-modal__features">
            <div class="premium-modal__feature" v-for="feature in premiumFeatures" :key="feature.title">
              <div class="premium-modal__feature-icon">
                <Icon v-if="feature.icon !== 'herospark'" :name="feature.icon" prefix="fal" :width="18" :height="18" />
                <img v-else src="@/assets/images/commons/herospark.svg" alt="HeroSpark" />
              </div>
              <h6>{{ feature.title }}</h6>
            </div>
          </div>

          <div class="premium-modal__cta">
            <MXButton variant="primary" @click="handleCta">
              Aproveitar o plano Premium
            </MXButton>
          </div>
        </div>
        <div class="premium-modal__side">
          <img src="@/assets/images/hero-original.png" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';
import MXButton from '@/shared/components/MXButton.vue';
export default {
  name: 'PremiumModal',
  components: {
    Icon,
    MXButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNormalModal: false,
    };
  },
  computed: {
    transitionName() {
      return this.isNormalModal ? 'fade-scale' : 'slide-up';
    },
    premiumFeatures() {
      return [
        {
          title: 'Campanhas de email para leads',
          icon: 'envelope',
        },
        {
          title: 'Importação de leads para o SparkFunnels',
          icon: 'cloud-arrow-up',
        },
        {
          title: 'Novo modelo de template de funil de lançamento',
          icon: 'filter',
        },
        {
          title: 'Remoção da marca HeroSpark nas páginas',
          icon: 'herospark',
        },
        {
          title: 'Automações personalizadas de emails',
          icon: 'location-arrow',
        },
        {
          title: 'Análise de entregabilidade de Emails',
          icon: 'analytics',
        },
      ];
    },
  },
  watch: {
    isOpen(newVal) {
      document.body.style.overflow = newVal ? 'hidden' : 'auto';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleCta() {
      this.$emit('click');
    },
    checkScreenSize() {
      this.isNormalModal = window.innerWidth > 768;
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    if (this.isOpen) {
      document.body.style.overflow = 'hidden';
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
    document.body.style.overflow = 'auto';
  },
};
</script>

<style lang="scss" scoped>
.responsive-modal {
  &__backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    z-index: 15;
  }

  &__inner {
    z-index: 15;
    position: fixed;
    background-color: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: opacity 0.3s ease-in-out;

    &--bottom-sheet {
      left: 0;
      right: 0;
      bottom: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      transform: translateY(0);

      .premium-modal__side {
        display: none;
      }
    }

    &--normal {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 8px;
      overflow: hidden;
      max-width: 1063px;
      width: 90%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__drag-indicator {
    width: 48px;
    height: 4px;
    background-color: #e2e8f0;
    border-radius: 9999px;
    margin: 0 auto 16px;
  }
}

.premium-modal {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    padding: 20px;
    flex: 1;
    max-width: 525px;
    margin: 0 auto;

    > h5,
    > p {
      text-align: center;
    }

    h5 {
      font-size: 24px;
      font-weight: 700;
      line-height: 115%;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      width: 482px;
    }
  }

  &__close-wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 20;
    padding: 8px;
  }

  &__close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 20;
    background: transparent;
    border: none;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__side {
    height: 100%;
    min-width: 422px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__features {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  &__feature {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

    h6 {
      max-width: 110px;
      font-size: 12px;
      font-weight: 700;
      line-height: 150%;
    }

    &-icon {
      min-width: 33px;
      min-height: 33px;
      border-radius: 999px;
      background-color: #bcfbff;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #2cc3cc;

      img {
        margin-left: -4px;
      }
    }
  }

  &__cta {
    margin-top: 32px;

    /deep/ .mx-button {
      width: 100%;
      background-color: #2cc3cc;
      border-radius: 2px;
      height: 36px;
      border: none;
      max-width: 247px;
      margin: 0 auto;

      .mx-button__text {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}

.fade-scale-enter-active,
.fade-scale-leave-active {
  transition: all 0.3s ease;
}

.fade-scale-enter,
.fade-scale-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9);
}

@media (min-width: 769px) {
  .responsive-modal__inner--bottom-sheet {
    max-height: 90vh;
    overflow-y: auto;
  }
}
</style>
