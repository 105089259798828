<template>
  <div class="accordion">
    <div class="accordion__header" @click="toggleAccordion">
      <div class="accordion__content">
        <Icon v-if="icon" :name="icon" :height="24" :width="24" :prefix="iconType" class="accordion__icon" />
        <div class="accordion__texts">
          <h2 class="accordion__title">{{ title }}</h2>
          <p class="accordion__subtitle">{{ subtitle }}</p>
        </div>
      </div>
      <div class="accordion__arrow" :class="{ 'accordion__arrow--open': isOpen }">
        <Icon name="chevron-down" prefix="fas" />
      </div>
    </div>
    <transition name="accordion">
      <div v-if="isOpen" class="accordion__body">
        <div class="accordion__body-content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';
export default {
  name: 'AccordionSection',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      default: 'fas',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  components: {
    Icon,
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  width: 100%;
  max-width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 4px 8px 0px #3f3f3f14;
  overflow: hidden;

  &__header {
    padding: 12px 16px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #262626;
    margin: 0;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__content {
    display: flex;
    gap: 14px;
    align-items: center;
    flex: 1;
    min-width: 0;
  }

  &__texts {
    flex: 1;
    min-width: 0;
  }

  &__subtitle {
    color: #525252;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    white-space: normal;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__icon {
    font-size: 16px;
    color: #6b7280;
    flex-shrink: 0;
  }

  &__arrow {
    color: #6b7280;
    transition: transform 0.3s ease;
    flex-shrink: 0;
    margin-left: 8px;

    &--open {
      transform: rotate(180deg);
    }
  }

  &__body {
    overflow: hidden;
    width: 100%;
  }

  &__body-content {
    border-top: 1px solid #e5e7eb;
    width: 100%;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 2000px; // Aumentado para acomodar conteúdos maiores
  opacity: 1;
}

.accordion-enter,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
