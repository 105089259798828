<template>
  <div class="crop">
    <div class="crop-container">
      <header class="crop-header">
        <h2 class="crop-header-title">Editar imagem do topo</h2>
        <button data-testid="header-close-button" @click="onClose">
          <hs-icon variant="solid" icon="times" />
        </button>
      </header>
      <section class="cropper">
        <p v-if="subtitle" class="cropper-subtitle">{{ subtitle }}</p>
        <cropper ref="cropper" :src="image" :stencil-props="{ aspectRatio }" />
      </section>
      <footer class="crop-actions">
        <MButton data-testid="cancel-button" variant="primary-outline" :disabled="isSaving" @click="onClose">
          Cancelar
        </MButton>
        <MButton data-testid="save-button" variant="primary" :disabled="isSaving" @click="onSave">
          {{ saveButtonText }}
        </MButton>
      </footer>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import MButton from '@/shared/components/MButton.vue';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper,
    MButton,
  },
  props: {
    aspectRatio: {
      type: Number,
      default: 1,
    },
    src: {
      type: String,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      image: null,
    };
  },
  computed: {
    saveButtonText() {
      return this.isSaving ? 'Salvando' : 'Salvar';
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave(e) {
      e.preventDefault();
      const result = this.$refs.cropper.getResult();

      result.canvas.toBlob(
        async file => {
          this.$emit('save', file);
        },
        'image/png',
        1.0
      );
    },
  },
  mounted() {
    this.image = this.src;
    this.$refs.cropper.setCoordinates({
      top: 20,
    });
  },
};
</script>

<style lang="scss" scoped>
.crop {
  align-items: center;
  background-color: rgba(#262626, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

.crop-container {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;

  @media (min-width: $screen-md) {
    width: 628px;
  }
}

.crop-header {
  align-items: center;
  border-bottom: 1px solid #bababa;
  box-sizing: border-box;
  display: flex;
  height: 52px;
  justify-content: space-between;
  padding: 24px;
}

.cropper-subtitle {
  font-size: 12px;
  color: #6f6f6f;
  margin-bottom: 10px;
}

.crop-header-title {
  font-size: 16px;
}

.cropper {
  padding: 14px 24px 24px;
  max-height: 500px;
  overflow: auto;
}

.crop-actions {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  column-gap: 16px;
}
</style>
