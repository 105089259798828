<template>
  <Accordeon
    :title="$t('members-area.components.geral-config.title')"
    :subtitle="$t('members-area.components.geral-config.subtitle')"
    icon="gear"
    iconType="fal"
  >
    <div class="tlw-py-6 tlw-flex tlw-flex-col tlw-px-4 md:tlw-px-6">
      <div class="tlw-flex tlw-flex-col tlw-gap-5">
        <div class="title">
          <h3 class="tlw-text-sm tlw-font-bold tlw-mb-2">
            {{ $t('members-area.components.geral-config.product-layout.title') }}
          </h3>
          <p class="tlw-text-sm tlw-mb-0">
            {{ $t('members-area.components.geral-config.product-layout.description') }}
          </p>
        </div>

        <div>
          <ProductNameOption @update:option="handleProductNameOptionChange" />
        </div>
      </div>

      <hr class="tlw-my-6" />

      <div class="tlw-flex tlw-flex-col tlw-gap-5">
        <div class="title">
          <h3 class="tlw-text-sm tlw-font-bold tlw-mb-2">
            {{ $t('members-area.components.geral-config.logo.title') }}
          </h3>
          <p class="tlw-text-sm tlw-mb-0">
            {{ $t('members-area.components.geral-config.logo.description') }}
          </p>
        </div>

        <div class="tlw-flex tlw-flex-row tlw-items-start tlw-items-center tlw-gap-4">
          <div class="logo-preview tlw-mb-4 md:tlw-mb-0">
            <img v-if="previewImage" :src="previewImage" alt="Logo Preview" class="preview-image" />
            <div v-else class="icon">
              <p>Logo</p>
            </div>
          </div>

          <div class="tlw-flex tlw-flex-col tlw-gap-2">
            <div class="tlw-flex tlw-gap-2 tlw-flex-wrap tlw-items-center">
              <MXButton
                variant="secondary"
                size="small"
                @click="openFileInput"
                :loading="isUploadingLogo"
                class="tlw-flex-grow md:tlw-flex-grow-0"
              >
                <Icon v-if="isUploadingLogo === false && !previewImage" name="cloud-arrow-up" prefix="fal" size="sm" />
                {{ logoButtonLabel }}
              </MXButton>

              <MXButton
                v-if="previewImage && !isUploadingLogo"
                variant="danger-tertiary"
                size="small"
                @click="showRemoveLogoModal"
                :icon="'far fa-trash-alt'"
              >
                <span class="tlw-hidden md:tlw-block">{{
                  $t('members-area.components.geral-config.logo.button.remove')
                }}</span>
              </MXButton>
            </div>

            <p v-if="logoError" class="error-text tlw-mb-2">{{ logoError }}</p>
          </div>

          <input ref="fileInput" type="file" accept=".jpg,.jpeg,.png" class="hidden-input" @change="handleFileSelect" />
        </div>
        <p class="helper-text">{{ $t('members-area.components.geral-config.logo.helper-text') }}</p>
      </div>

      <hr class="tlw-my-6" />

      <div class="tlw-flex tlw-flex-col tlw-gap-5">
        <div class="title">
          <h3 class="tlw-text-sm tlw-font-bold tlw-mb-1 tlw-flex tlw-items-center tlw-gap-1">
            {{ $t('members-area.components.geral-config.favicon.title') }}
            <template v-if="!isMobile">
              <m-tooltip
                image="https://picsum.photos/200/300"
                :title="$t('members-area.components.geral-config.favicon.tooltip-title')"
                :description="$t('members-area.components.geral-config.favicon.tooltip-description')"
                @primary-click="handleContinue"
                @secondary-click="handleReadMore"
              >
                <template #trigger>
                  <button class="question-icon">
                    <Icon name="question-circle" prefix="fal" size="sm" />
                  </button>
                </template>
              </m-tooltip>
            </template>
            <template v-else>
              <mobile-tooltip
                image="https://picsum.photos/200/300"
                :title="$t('members-area.components.geral-config.favicon.tooltip-title')"
                :description="$t('members-area.components.geral-config.favicon.tooltip-description')"
                @primary-click="handleContinue"
                @secondary-click="handleReadMore"
              >
                <template #trigger>
                  <button class="question-icon">
                    <Icon name="question-circle" prefix="fal" size="sm" />
                  </button>
                </template>
              </mobile-tooltip>
            </template>
          </h3>
          <p class="tlw-text-sm tlw-mb-0">
            {{ $t('members-area.components.geral-config.favicon.description') }}
          </p>
        </div>

        <div class="tlw-flex tlw-items-center tlw-gap-4">
          <div class="favicon-preview tlw-mb-4 sm:tlw-mb-0">
            <div class="favicon-image" v-if="faviconPreview">
              <img :src="faviconPreview" alt="Favicon Preview" class="favicon-image-preview" />
            </div>
            <div v-else class="icon">
              <p>Favicon</p>
            </div>
          </div>

          <div class="tlw-flex tlw-gap-2">
            <div class="tlw-flex tlw-gap-2 tlw-flex-wrap tlw-items-center">
              <MXButton
                variant="secondary"
                size="small"
                @click="openFaviconInput"
                :loading="isUploadingFavicon"
                :full-width="!faviconPreview"
                class="tlw-flex-grow md:tlw-flex-grow-0"
              >
                <Icon
                  v-if="isUploadingFavicon === false && !faviconPreview"
                  name="cloud-arrow-up"
                  prefix="fal"
                  size="sm"
                />
                {{ faviconButtonLabel }}
              </MXButton>

              <MXButton
                v-if="faviconPreview && !isUploadingFavicon"
                variant="danger-tertiary"
                size="small"
                :icon="'far fa-trash-alt'"
                @click="removeFavicon"
              >
                <span class="tlw-hidden md:tlw-block">{{
                  $t('members-area.components.geral-config.favicon.button.remove')
                }}</span>
              </MXButton>
            </div>

            <p v-if="faviconError" class="error-text tlw-mt-2">{{ faviconError }}</p>
          </div>

          <input
            ref="faviconInput"
            type="file"
            accept=".ico,.svg,.png,.gif"
            class="hidden-input"
            @change="handleFaviconSelect"
          />
        </div>
        <p class="helper-text">{{ $t('members-area.components.geral-config.favicon.helper-text') }}</p>
      </div>
    </div>

    <crop-image
      v-if="showCropper"
      :src="selectedImage"
      :aspect-ratio="16 / 9"
      :is-saving="isSaving"
      @save="handleCropSave"
      @close="showCropper = false"
    />

    <remove-logo-modal ref="removeLogoModal" @confirm="handleLogoRemoveConfirm" @cancel="handleLogoRemoveCancel" />
  </Accordeon>
</template>

<script>
import Accordeon from '../Accordeon.vue';
import ProductNameOption from '../ProductNameOption.vue';
import Icon from '@/components-new/Icon.vue';
import CropImage from '@/components/CropImage.vue';
import MTooltip from '@/shared/components/MTooltip.vue';
import RemoveLogoModal from '../RemoveLogoModal.vue';
import BrowserHelper from '@/shared/helpers/browser';
import MobileTooltip from '../MobileTooltip.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';
import uploadService from '@/services/upload';
import MXButton from '@/shared/components/MXButton.vue';

export default {
  components: {
    Accordeon,
    ProductNameOption,
    Icon,
    CropImage,
    MTooltip,
    RemoveLogoModal,
    MobileTooltip,
    MXButton,
  },
  data() {
    return {
      showCropper: false,
      selectedImage: null,
      isSaving: false,
      faviconError: null,
      logoError: null,
      isUploadingFavicon: false,
      isUploadingLogo: false,
    };
  },

  mounted() {
    this.$root.$on('open-geral-config', this.openAccordion);
  },

  beforeDestroy() {
    this.$root.$off('open-geral-config', this.openAccordion);
  },

  computed: {
    ...mapState('membersArea', ['configs']),
    ...mapState('school', ['selectedSchool']),
    ...mapGetters('membersArea', ['getGeneralConfig']),

    isMobile() {
      return BrowserHelper.isMobile();
    },
    previewImage() {
      return this.selectedSchool?.logo || null;
    },
    faviconPreview() {
      return this.getGeneralConfig.favicon_url || null;
    },
    logoButtonLabel() {
      if (this.isUploadingLogo) return this.$t('members-area.components.geral-config.logo.button.loading');

      return this.previewImage
        ? this.$t('members-area.components.geral-config.logo.button.change')
        : this.$t('members-area.components.geral-config.logo.button.upload');
    },
    faviconButtonLabel() {
      if (this.isUploadingFavicon) return this.$t('members-area.components.geral-config.favicon.button.loading');

      return this.faviconPreview
        ? this.$t('members-area.components.geral-config.favicon.button.change')
        : this.$t('members-area.components.geral-config.favicon.button.upload');
    },
  },
  methods: {
    ...mapActions('membersArea', ['updateGeneralConfig', 'uploadFavicon']),
    ...mapActions('school', ['updateSchool']),
    handleProductNameOptionChange(option) {
      this.toggleProductName(option === 'with-name');
    },

    openAccordion() {
      const accordeonComponent = this.$children.find(child => child.$options.name === 'AccordionSection');
      if (accordeonComponent) {
        accordeonComponent.isOpen = true;
      }
    },

    async toggleProductName(value) {
      try {
        await this.updateGeneralConfig({
          key: 'show_product_name',
          value: value,
          schoolId: this.selectedSchool.id,
        });
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('Erro ao atualizar a visibilidade do nome do produto!'));
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    async uploadConfigImage(file) {
      try {
        const { data } = await uploadService.getAwsKeys(file.type, this.selectedSchool.id);
        const imageUrl = await uploadService.upload(data.url, data.fields, file);

        const school = this.selectedSchool;
        delete school.phone;
        await this.updateSchool({ ...school, logo: imageUrl });
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('Erro ao salvar a imagem!'));
        throw error;
      }
    },
    async handleFileSelect(e) {
      const file = e.target.files[0];
      if (!file) return;

      // Reset error state
      this.logoError = null;

      // Check file type
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!validTypes.includes(file.type)) {
        this.logoError = 'Por favor, selecione um arquivo no formato .PNG, .JPG ou .JPEG';
        e.target.value = '';
        return;
      }

      // Check file size (1MB = 1048576 bytes)
      if (file.size > 1048576) {
        this.logoError = 'O arquivo deve ter no máximo 1MB';
        e.target.value = '';
        return;
      }

      try {
        this.isUploadingLogo = true;
        await this.uploadConfigImage(file);
        ToastHelper.successMessage(this.$t('A imagem foi salva com sucesso!'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('Erro ao salvar a imagem!'));
      } finally {
        this.isUploadingLogo = false;
        // Clear the file input
        e.target.value = '';
      }
    },
    async handleCropSave(croppedFile) {
      try {
        this.isSaving = true;
        // Create preview URL for the cropped image
        this.previewImage = URL.createObjectURL(croppedFile);

        // Here you would typically upload the file to your server first
        // const uploadedUrl = await uploadFileToServer(croppedFile);
        const uploadedUrl = this.previewImage; // Replace with actual upload URL

        await this.updateGeneralConfig({
          key: 'logo_url',
          value: uploadedUrl,
          schoolId: this.selectedSchool.id,
        });

        this.showCropper = false;
      } catch (error) {
        alert('Erro ao salvar a imagem. Por favor, tente novamente.');
      } finally {
        this.isSaving = false;
      }
    },
    openFaviconInput() {
      this.$refs.faviconInput.click();
    },
    async handleFaviconSelect(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Reset error state
      this.faviconError = null;

      // Check file type
      const validTypes = [
        'image/x-icon',
        'image/svg+xml',
        'image/png',
        'image/gif',
        'image/vnd.microsoft.icon',
        'image/ico',
      ];

      if (!validTypes.includes(file.type)) {
        this.faviconError = 'Por favor, selecione um arquivo no formato .ICO, .SVG, .PNG ou .GIF';
        event.target.value = '';
        return;
      }

      // Check file size (50KB = 51200 bytes)
      if (file.size > 51200) {
        this.faviconError = 'O arquivo deve ter no máximo 50KB';
        event.target.value = '';
        return;
      }

      try {
        this.isUploadingFavicon = true;
        await this.uploadFavicon({ file, schoolId: this.selectedSchool.id });
        ToastHelper.successMessage(this.$t('Favicon carregado com sucesso!'));
      } catch (error) {
        this.faviconError = 'Erro ao salvar o favicon. Por favor, tente novamente.';
        ToastHelper.dangerMessage(this.$t('Erro ao fazer upload do favicon!'));
      } finally {
        this.isUploadingFavicon = false;
        event.target.value = '';
      }
    },
    handleContinue() {
      // Handle continue button click
    },
    handleReadMore() {
      // Handle read more button click
    },
    async showRemoveLogoModal() {
      this.$bvModal.show('remove-logo-modal');
    },
    async handleLogoRemoveConfirm() {
      try {
        const school = this.selectedSchool;
        delete school.phone;
        await this.updateSchool({ ...school, logo: null });

        this.$bvModal.hide('remove-logo-modal');
        ToastHelper.successMessage(this.$t('A logo foi removida com sucesso!'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('Erro ao remover a logo!'));
      }
    },
    handleLogoRemoveCancel() {
      this.$bvModal.hide('remove-logo-modal');
    },
    async removeFavicon() {
      try {
        await this.uploadFavicon({ file: null, schoolId: this.selectedSchool.id });
        await this.updateGeneralConfig({
          key: 'favicon_url',
          value: null,
          schoolId: this.selectedSchool.id,
        });
        ToastHelper.successMessage(this.$t('O favicon foi removido com sucesso!'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('Erro ao remover o favicon!'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0;
}

.icon {
  width: 89px;
  height: 89px;
  background-color: #efefef;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #929292;
  border: 1px solid #929292;
}

.icon p {
  margin: 0;
}

.title p {
  color: #525252;
}

.helper-text {
  color: #6f6f6f;
  font-size: 12px;
}

.custom-tooltip {
  background: rgba(38, 38, 38, 0.95);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  width: 320px;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);

  &__image {
    width: 100%;
    height: 162px;
    background: rgba(26, 26, 26, 0.5);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 70%;
      background: linear-gradient(to bottom, transparent, rgba(38, 38, 38, 0.95));
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.9;
    }
  }

  &__content {
    padding: 16px;
    background: rgba(38, 38, 38, 0.95);
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 8px 0;
    color: #ffffff;
    text-align: start;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 21px;
    color: #bababa;
    margin: 0 0 24px 0;
    text-align: start;
  }

  &__buttons {
    display: flex;
    gap: 12px;
  }

  &__button {
    flex: 1;
    height: 40px;
    font-size: 14px;
    padding: 8px 16px;

    &:first-child {
      // Style for "Continuar" button
      border: 1px solid #7427f1;
      color: #7427f1;
      background: transparent;
    }

    &:last-child {
      // Style for "Ler mais" button
      background: #7427f1;
      color: white;
      border: none;
    }
  }
}

.question-icon {
  background-color: transparent;
  border-radius: 50%;
  padding: 6px;
  color: #3575d4;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: #efefef;
    color: #1e4278;
  }
}

.hidden-input {
  display: none;
}

.logo-preview {
  width: 142px;
  height: 71px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #929292;

  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.favicon-preview {
  width: 89px;
  height: 89px;
  border-radius: 8px;
  overflow: hidden;

  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .favicon-image {
    height: 100%;
    border-radius: 8px;
    border: 1px solid #929292;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;

    .favicon-image-preview {
      border: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.icon {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #929292;
  border: 1px solid #929292;

  p {
    margin: 0;
  }
}

.helper-text {
  color: #6f6f6f;
  font-size: 12px;
}

.button-icon {
  flex: 1;
  height: 39px;
  @media screen and (min-width: 768px) {
    flex: unset;
  }
  @media screen and (max-width: 380px) {
    width: 100%;
  }
}

.trash-icon {
  height: 39px;

  color: #db3939;
  border: 0;
}

.error-text {
  color: #db3939;
  font-size: 12px;
  margin: 0;
}
</style>
