<template>
  <div class="cards-container tlw-flex tlw-flex-col md:tlw-flex-row tlw-gap-4">
    <!-- Option with product name -->
    <div
      class="option-card tlw-w-full tlw-rounded-lg tlw-overflow-hidden tlw-border-2 tlw-transition-colors tlw-cursor-pointer"
      :class="selectedOption === 'with-name' ? 'tlw-border-purple-500' : 'tlw-border-gray-200'"
      @click="selectOption('with-name')"
    >
      <div class="card-header tlw-hidden md:tlw-block">
        <img class="card-image" src="@/assets/images/productcard_title.svg" alt="" />
      </div>

      <div class="tlw-p-2">
        <div class="tlw-flex tlw-items-center tlw-gap-2">
          <div class="tlw-relative tlw-w-4 tlw-h-4 tlw-flex tlw-items-center tlw-justify-center">
            <input
              type="radio"
              name="productNameOption"
              id="option-with-name"
              value="with-name"
              v-model="selectedOption"
              class="tlw-appearance-none tlw-w-4 tlw-h-4 tlw-rounded-full tlw-border-2 tlw-checked:border-purple-600 tlw-focus:outline-none"
              :class="
                selectedOption === 'with-name'
                  ? 'tlw-border-purple-600 tlw-bg-purple-600'
                  : 'tlw-border-gray-200 tlw-bg-white'
              "
            />
            <div
              class="tlw-pointer-events-none tlw-absolute tlw-w-2 tlw-h-2 tlw-rounded-full tlw-transition-transform tlw-duration-200 tlw-ease-in-out"
              :class="selectedOption === 'with-name' ? 'tlw-bg-white tlw-scale-100' : 'tlw-scale-0'"
            ></div>
          </div>
          <span class="tlw-font-bold">Com nome do produto</span>
        </div>
        <p class="tlw-text-gray-600 tlw-text-sm tlw-ml-6 tlw-mb-0">
          Exibe o nome do produto, facilitando a identificação
        </p>
      </div>
    </div>

    <!-- Option without product name -->
    <div
      class="option-card tlw-w-full tlw-rounded-lg tlw-overflow-hidden tlw-border-2 tlw-transition-colors tlw-cursor-pointer"
      :class="selectedOption === 'without-name' ? 'tlw-border-purple-500' : 'tlw-border-gray-200'"
      @click="selectOption('without-name')"
    >
      <div class="card-header tlw-hidden md:tlw-block">
        <img class="card-image" src="@/assets/images/productcard.svg" alt="" />
      </div>

      <div class="tlw-p-2">
        <div class="tlw-flex tlw-items-center tlw-gap-2">
          <div class="tlw-relative tlw-w-4 tlw-h-4 tlw-flex tlw-items-center tlw-justify-center">
            <input
              type="radio"
              name="productNameOption"
              id="option-without-name"
              value="without-name"
              v-model="selectedOption"
              class="tlw-appearance-none tlw-w-4 tlw-h-4 tlw-rounded-full tlw-border-2 tlw-checked:border-purple-600 tlw-focus:outline-none"
              :class="
                selectedOption === 'without-name'
                  ? 'tlw-border-purple-600 tlw-bg-purple-600'
                  : 'tlw-border-gray-200 tlw-bg-white'
              "
            />
            <div
              class="tlw-pointer-events-none tlw-absolute tlw-w-2 tlw-h-2 tlw-rounded-full tlw-transition-transform tlw-duration-200 tlw-ease-in-out"
              :class="selectedOption === 'without-name' ? 'tlw-bg-white tlw-scale-100' : 'tlw-scale-0'"
            ></div>
          </div>
          <span class="tlw-font-bold">Sem nome do produto</span>
        </div>
        <p class="tlw-text-gray-600 tlw-text-sm tlw-ml-6 tlw-mb-0">
          Não mostra o nome, focando na imagem do produto
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProductNameOption',
  data() {
    return {
      selectedOption: 'with-name',
    };
  },
  computed: {
    ...mapGetters('membersArea', ['getGeneralConfig']),
  },
  mounted() {
    this.selectedOption = this.getGeneralConfig.show_product_name ? 'with-name' : 'without-name';
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.$emit('update:option', option);
    },
  },
};
</script>

<style lang="scss" scoped>
.cards-container {
  max-height: 175px;
}

.option-card {
  transition: all 0.2s ease-in-out;
}

.option-card:hover {
  border-color: #9333ea;
  box-shadow: 0px 4px 8px 0px #3f3f3f14;
  background-color: #f3ebfa;

  input {
    background-color: #f3ebfa;
    border-color: #9333ea;
  }
}

.card-image {
  max-width: 64px;
  width: 100%;
}

.card-header {
  width: 100%;
  height: 98px;
  background-color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    display: none;
  }
}
</style>
