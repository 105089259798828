<template>
  <div class="section tlw-px-4 tlw-py-6 tlw-flex tlw-flex-col tlw-gap-3">
    <p class="text">Seção fixa</p>

    <div class="section__content tlw-border tlw-flex tlw-gap-4 tlw-rounded-lg tlw-p-3">
      <div class="image-wrapper">
        <img class="image" :src="image" alt="" />
      </div>
      <div class="tlw-flex tlw-flex-col">
        <h4 class="tlw-text-[#262626] tlw-font-bold tlw-text-sm">Meus produtos</h4>
        <p class="mb-0 tlw-text-sm tlw-text-[#525252]">Mostra todos os produtos que o aluno já comprou.</p>
        <div
          class="format-options tlw-mt-3 tlw-flex tlw-flex-col tlw-gap-2 sm:tlw-flex-row sm:tlw-gap-4 sm:tlw-items-center"
        >
          <span class="tlw-text-xs">Formato da capa:</span>
          <div class="tlw-flex tlw-items-center">
            <div class="tabs">
              <input
                type="radio"
                value="horizontal"
                id="radio-background-1"
                name="tabs-background"
                v-model="selectedType"
                checked
                @change="handleTypeChange"
              />
              <label class="tab" for="radio-background-1">
                <img src="@/assets/images/MembersArea/rectangle-box.svg" alt="rectangle-box" />
              </label>
              <input
                type="radio"
                value="vertical"
                id="radio-background-2"
                name="tabs-background"
                v-model="selectedType"
                @change="handleTypeChange"
              />
              <label class="tab" for="radio-background-2">
                <img src="@/assets/images/MembersArea/rectangle-box-2.svg" alt="rectangle-box" />
              </label>
              <span class="glider"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import ToastHelper from '@/shared/helpers/toast';
import imageVerticalWithTitle from '@/assets/images/MembersArea/my-products-vertical-with-title.png';
import imageVerticalWithoutTitle from '@/assets/images/MembersArea/my-products-vertical-without-title.png';
import imageHorizontalWithTitle from '@/assets/images/MembersArea/my-products-horizontal-with-title.png';
import imageHorizontalWithoutTitle from '@/assets/images/MembersArea/my-products-horizontal-without-title.png';

export default {
  name: 'MyProductsSection',
  data() {
    return {
      selectedType: 'horizontal',
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('membersArea', ['configs']),
    ...mapGetters('membersArea', ['getGeneralConfig']),
    image() {
      const hasProductName = this.getGeneralConfig.show_product_name;

      if (hasProductName) {
        return this.selectedType === 'horizontal' ? imageHorizontalWithTitle : imageVerticalWithTitle;
      }

      return this.selectedType === 'horizontal' ? imageHorizontalWithoutTitle : imageVerticalWithoutTitle;
    },
  },
  methods: {
    ...mapActions('membersArea', ['updateSectionConfig']),

    async handleTypeChange() {
      try {
        await this.updateSectionConfig({
          key: 'card_format',
          value: this.selectedType,
          schoolId: this.selectedSchool.id,
          kind: 'school_products',
        });
      } catch (error) {
        ToastHelper.errorMessage('Erro ao atualizar configuração da seção');
      }
    },
  },
  mounted() {
    this.selectedType = this.configs.sections.find(section => section.kind === 'school_products').card_format;
  },
};
</script>

<style lang="scss" scoped>
.section {
  &__content {
    border: 1px solid #bababa;
    background-color: #efefef;
    height: auto;

    > div.image-wrapper {
      width: 100%;
      height: 145px;
      max-width: 109px;
      border-radius: 8px;
      overflow: hidden;
      background-color: #171717;

      @media (min-width: 640px) {
        max-height: 97px;
        max-width: 169px;
      }

      > img.image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
        border-radius: 8px;
      }
    }

    .text {
      font-size: 12px;
      color: #6f6f6f;
      margin-bottom: 0;
    }
  }
}

input[type='radio'] {
  display: none;
}

.tabs {
  display: flex;
  position: relative;
  background-color: #cfcfcf;
  padding: 2px;
  border-radius: 8px;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 51px;
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  margin-bottom: 0;
  padding: 3px 16px;
  z-index: 2;
}

input[type='radio'] {
  &:checked {
    & + label {
      color: #262626;
      font-weight: bold;
    }
  }
}

input[id='radio-background-1'] {
  &:checked {
    & ~ .glider {
      transform: translateX(0);
    }
  }
}

input[id='radio-background-2'] {
  &:checked {
    & ~ .glider {
      transform: translateX(100%);
    }
  }
}

.glider {
  position: absolute;
  display: flex;
  height: 24px;
  width: 51px;
  background-color: white;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 1px 0px #00000008;
  box-shadow: 0px 2px 8px 0px #0000001a;
  transition: 0.25s ease-out;
}
</style>
