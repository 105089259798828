<template>
  <div class="tlw-inline-block">
    <div @click="showModal = true">
      <slot name="trigger"></slot>
    </div>

    <Transition
      enter-active-class="tlw-transition-all tlw-duration-300 tlw-ease-out"
      enter-from-class="tlw-opacity-0"
      enter-to-class="tlw-opacity-100"
      leave-active-class="tlw-transition-all tlw-duration-200 tlw-ease-in"
      leave-from-class="tlw-opacity-100"
      leave-to-class="tlw-opacity-0"
    >
      <div v-if="showModal" class="mobile-tooltip">
        <div class="mobile-tooltip__overlay" @click="showModal = false"></div>

        <Transition
          enter-active-class="tlw-transition-all tlw-duration-300 tlw-ease-out"
          enter-from-class="tlw-translate-y-full"
          enter-to-class="tlw-translate-y-0"
          leave-active-class="tlw-transition-all tlw-duration-200 tlw-ease-in"
          leave-from-class="tlw-translate-y-0"
          leave-to-class="tlw-translate-y-full"
        >
          <div v-if="showModal" class="mobile-tooltip__content">
            <div class="mobile-tooltip__header">
              <button class="mobile-tooltip__close" @click="showModal = false">
                <Icon name="times" prefix="fal" />
              </button>
            </div>

            <div class="mobile-tooltip__image">
              <img :src="image" :alt="title" />
            </div>

            <div class="mobile-tooltip__body">
              <h3>{{ title }}</h3>
              <p>{{ description }}</p>

              <div class="mobile-tooltip__buttons">
                <MButton variant="primary-outline" label="Continuar" @click="handleContinue" />
                <MButton variant="primary" label="Ler mais" @click="handleReadMore" />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';
import MButton from '@/shared/components/MButton.vue';

export default {
  name: 'MobileTooltip',
  components: {
    Icon,
    MButton,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    handleContinue() {
      this.$emit('primary-click');
      this.showModal = false;
    },
    handleReadMore() {
      this.$emit('secondary-click');
      this.showModal = false;
    },
  },
  watch: {
    showModal(newValue) {
      if (newValue) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-tooltip {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
  }

  &__content {
    position: relative;
    background: #262626;
    padding: 16px;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    max-height: calc(100vh - 32px);
    display: flex;
    flex-direction: column;
    will-change: transform;
  }

  &__header {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
  }

  &__handle {
    width: 32px;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    margin: 0 auto;
  }

  &__close {
    color: #fff;
    background: none;
    border: none;
    font-size: 12px;
    line-height: 1;
    margin-left: auto;
  }

  &__image {
    width: 100%;
    height: 200px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 70%;
      background: linear-gradient(to bottom, transparent, #262626);
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  &__body {
    margin-top: 20px;
    overflow-y: auto;

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      color: #bababa;
      margin-bottom: 20px;
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;

    button {
      &:first-child {
        color: #ffffff;
        text-decoration: underline;
        border: 0;
      }
    }
  }
}
</style>
