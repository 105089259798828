<template>
  <div>
    <Accordeon
      :title="$t('members-area.components.footer-config.title')"
      :subtitle="$t('members-area.components.footer-config.subtitle')"
      icon="file-signature"
      iconType="fal"
    >
      <div class="footer-config">
        <div class="footer-config__logo">
          <div class="footer-config__logo-left">
            <p>{{ $t('members-area.components.footer-config.hide-logo.label') }}</p>
            <span class="footer-config__logo-info">
              <img src="@/assets/images/rounded-star.svg" alt="Rounded Star" />
              {{ $t('members-area.components.footer-config.hide-logo.pro-badge') }}
            </span>
          </div>
          <template v-if="isFreeUser">
            <MSwitch :value="hideHerosparkLogo" @click.native.prevent="openPremiumModal" />
          </template>
          <template v-else>
            <MSwitch v-model="hideHerosparkLogo" @change="handlePaidUserSwitchChange" />
          </template>
        </div>
        <p class="footer-config__logo-info">
          {{ $t('members-area.components.footer-config.hide-logo.description') }}
        </p>

        <hr />
        <div v-if="!hasCustomization" class="footer-config__empty">
          <div class="footer-config__empty-card"></div>

          <h4>{{ $t('members-area.components.footer-config.empty-state.title') }}</h4>
          <p>{{ $t('members-area.components.footer-config.empty-state.description') }}</p>

          <MXButton variant="primary" @click="openDrawer">
            <Icon name="plus" prefix="fal" :width="20" :height="20" />
            {{ $t('members-area.components.footer-config.empty-state.button') }}
          </MXButton>
        </div>

        <div v-else class="footer-config__preview">
          <div class="footer-config__preview-content">
            <div v-if="previewImage" class="footer-config__preview-logo">
              <h3 class="tlw-text-sm tlw-font-bold">{{ $t('members-area.components.footer-config.preview.logo') }}</h3>
              <div class="logo-preview">
                <img v-if="previewImage" :src="previewImage" alt="Logo Preview" class="preview-image" />
                <div v-else class="icon-empty">
                  <p>Logo</p>
                </div>
              </div>
            </div>

            <div v-if="previewText" class="footer-config__preview-text">
              <h3 class="tlw-text-sm tlw-font-bold">{{ $t('members-area.components.footer-config.preview.text') }}</h3>
              <div class="tlw-mr-4 md:tlw-mr-0 tlw-text-end">
                <p>{{ previewText }}</p>
              </div>
            </div>

            <div v-if="previewLinks.length > 0" class="footer-config__preview-links">
              <h3 class="tlw-text-sm tlw-font-bold">{{ $t('members-area.components.footer-config.preview.links') }}</h3>
              <div class="footer-config__preview-links-wrapper">
                <div v-for="link in previewLinks" :key="link.id" class="footer-config__preview-links-item">
                  <Icon :name="getIconName(link.icon)" :prefix="getIconPrefix(link.icon)" :width="20" :height="20" />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="footer-config__preview-actions">
            <MXButton :variant="buttonVariant" @click="openDrawer">
              <Icon name="pencil-alt" prefix="fal" :width="16" :height="16" v-if="hasCustomization" />
              <Icon name="plus" prefix="fal" :width="16" :height="16" v-else />
              {{
                hasCustomization
                  ? $t('members-area.components.footer-config.preview.button.edit')
                  : $t('members-area.components.footer-config.preview.button.add')
              }}
            </MXButton>
            <MXButton v-if="!hasDeleted" variant="tertiary" @click="deleteFooterConfig">
              <Icon name="trash-alt" prefix="fal" class="icon-trash" />
            </MXButton>
          </div>
        </div>
      </div>
    </Accordeon>

    <FooterDrawer v-if="isDrawerOpen" :is-open="isDrawerOpen" @close="closeDrawer" @saved="handleSaved" />
    <PremiumModal :is-open="isModalOpen" @close="closeModal" @click="handleCta" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Accordeon from '../Accordeon.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import MXButton from '@/shared/components/MXButton.vue';
import FooterDrawer from '../footer/FooterDrawer.vue';
import Icon from '@/components-new/Icon.vue';
import PremiumModal from '../PremiumModal.vue';
import footerIconMixin from '@/views/MembersArea/mixins/footerIconMixin';

export default {
  name: 'FooterConfig',

  components: {
    Accordeon,
    MSwitch,
    MXButton,
    FooterDrawer,
    Icon,
    PremiumModal,
  },

  data() {
    return {
      isDrawerOpen: false,
      isModalOpen: false,
      hideHerosparkLogo: false,
    };
  },

  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('membersArea', ['hasDefaultFooter', 'configs']),

    buttonText() {
      if (this.hasCustomization) return this.$t('members-area.components.footer-config.buttons.edit');
      return this.$t('members-area.components.footer-config.buttons.add');
    },

    buttonVariant() {
      if (this.hasCustomization) return 'secondary';
      return 'primary';
    },

    previewImage() {
      return this.selectedSchool?.logo || null;
    },
    previewText() {
      return this.configs.footer.show_custom_text ? this.configs.footer.custom_text : null;
    },
    previewLinks() {
      return (this.configs.footer.show_external_links && this.configs.footer.external_links) || [];
    },
    hasCustomization() {
      if (this.hasDefaultFooter) return false;
      return !this.configs.footer.deleted;
    },
    hasDeleted() {
      return this.configs.footer.deleted || false;
    },
  },

  methods: {
    ...mapActions('membersArea', ['updateFooterConfig', 'removeFooterConfig']),

    openDrawer() {
      this.isDrawerOpen = true;
    },

    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },

    handleCta() {
      this.$router.push({ name: 'MyAccountPlans' });
    },

    closeDrawer() {
      this.isDrawerOpen = false;
    },

    deleteFooterConfig() {
      this.removeFooterConfig({
        schoolId: this.selectedSchool.id,
      });
    },

    openPremiumModal() {
      this.openModal();
    },

    handlePaidUserSwitchChange(value) {
      this.updateFooterConfig({
        data: {
          show_herospark_logo: !value,
        },
        schoolId: this.selectedSchool.id,
      });
    },

    handleSwitchInput(value) {
      if (this.isFreeUser) {
        this.openModal();
        return;
      }

      this.hideHerosparkLogo = value;
      this.handlePaidUserSwitchChange(value);
    },

    handleShowHerosparkLogoChange(value) {
      this.handleSwitchInput(value);
    },

    handleSaved() {},
  },

  created() {
    this.hideHerosparkLogo = !this.configs.footer.show_herospark_logo || false;
    this.isFreeUser = !this.$FCL.hasDeactivateCopyright();
  },

  mixins: [footerIconMixin],
};
</script>

<style lang="scss" scoped>
.footer-config {
  padding: 24px 16px;

  @media screen and (max-width: 768px) {
    padding: 16px 8px;
  }

  &__preview-text {
    h3 {
      color: #262626;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    &-left {
      display: flex;
      align-items: center;
      gap: 12px;

      @media screen and (max-width: 768px) {
        gap: 8px;
        flex-wrap: wrap;
      }

      p {
        color: #262626;
        font-size: 14px;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 0;
      }

      span {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
        font-size: 12px;
        color: #fff;
        border-radius: 200px;
        background: linear-gradient(90deg, #ff305c 0%, #7a094c 100%);
        padding: 4px 12px;

        @media screen and (max-width: 768px) {
          padding: 4px 8px;
          gap: 4px;
        }
      }
    }

    &-info {
      color: #525252;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-card {
      content: '';
      height: 156px;
      width: 100%;
      border-radius: 8px;
      background: #262626;
      margin-bottom: 20px;
    }

    h4 {
      color: #262626;
      font-size: 20px;
      font-weight: 700;
      line-height: 125%;
      margin-bottom: 12px;
      text-align: center;
    }

    p {
      color: #6f6f6f;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 20px;
      text-align: center;
    }

    /deep/ .mx-button__text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }

  hr {
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    &__logo {
      gap: 8px;
      &-left {
        gap: 8px;
        span {
          padding: 4px 8px;
          gap: 4px;
        }
      }
    }
  }
}

.footer-config__preview {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    p {
      margin: 0;
    }
  }

  &-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .logo-preview {
      width: 142px;
      height: 71px;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid #929292;

      .preview-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      .icon-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 142px;
        height: 71px;
        border-radius: 8px;
        background-color: #f5f5f5;
        border: 1px solid #bababa;

        p {
          margin: 0;
        }
      }
    }
  }

  &-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 64px;
    color: #525252;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }

  &-links {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 10px;
      border-radius: 999px;
      border: 1px solid #cfcfcf;
      color: #3f3f3f;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 20px;

    /deep/ .mx-button__text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    button:first-child {
      flex: 1;
    }
  }
}

.icon-trash {
  color: #db3939;
}
</style>
