<template>
  <transition name="slide-fade" mode="out-in">
    <Drawer
      :is-open="isOpen"
      :title="$t('members-area.components.footer-config.drawer.title')"
      class="relative"
      @close="$emit('close')"
    >
      <!-- Logo Section -->
      <div class="logo-section">
        <h3 class="tlw-text-sm tlw-font-bold tlw-mb-2">
          {{ $t('members-area.components.footer-config.drawer.logo.title') }}
        </h3>
        <div v-if="previewImage" class="tlw-flex tlw-items-start tlw-gap-1 tlw-text-sm tlw-text-gray-600 tlw-mb-4">
          <p>
            {{ $t('members-area.components.footer-config.drawer.logo.description-1') }}
            <span class="access-link" @click="openGeneralSettings">
              {{ $t('members-area.components.footer-config.drawer.logo.description-2') }}
            </span>
            {{ $t('members-area.components.footer-config.drawer.logo.description-3') }}
          </p>
        </div>
        <div v-if="previewImage" class="logo-preview tlw-mr-4 md:tlw-mr-0">
          <img :src="previewImage" alt="Logo Preview" class="preview-image" />
        </div>

        <div v-else class="tlw-flex tlw-items-start tlw-gap-1 tlw-text-sm tlw-text-gray-600 tlw-mb-4">
          <Icon name="info-circle" prefix="fal" :height="16" :width="16" class="tlw-mt-0.5" />
          <p>
            {{ $t('members-area.components.footer-config.drawer.logo.description-4') }}
            <span class="access-link" @click="openGeneralSettings">
              {{ $t('members-area.components.footer-config.drawer.logo.description-5') }}
            </span>
            {{ $t('members-area.components.footer-config.drawer.logo.description-6') }}
          </p>
        </div>
      </div>

      <hr />

      <!-- Footer Text Section -->
      <div class="text-section">
        <div class="text-section__header">
          <div>
            <h3 class="tlw-text-sm tlw-font-bold tlw-mb-2">
              {{ $t('members-area.components.footer-config.drawer.text.title') }}
            </h3>
            <MSwitch v-model="showFooterText" />
          </div>
          <p>
            {{ $t('members-area.components.footer-config.drawer.text.description') }}
          </p>
        </div>
        <transition name="fade">
          <TextArea
            v-if="showFooterText"
            v-model="footerText"
            id="footer-text"
            :placeholder="$t('members-area.components.footer-config.drawer.text.text-area.placeholder')"
            :maxLength="150"
            :label="$t('members-area.components.footer-config.drawer.text.text-area.label')"
            height="120px"
            :hasError="footerTextError"
            @input="clearFooterTextError"
          />
        </transition>
        <transition name="fade">
          <p v-if="showFooterText && footerTextError" class="tlw-text-red-500 tlw-text-xs tlw-mt-1">
            {{ $t('members-area.components.footer-config.drawer.text.text-area.error') }}
          </p>
        </transition>
      </div>

      <hr />

      <div class="tlw-mb-6">
        <div class="tlw-flex tlw-justify-between tlw-items-center tlw-mb-4">
          <h3 class="tlw-text-sm tlw-font-bold">
            {{ $t('members-area.components.footer-config.drawer.external-links.title') }}
          </h3>
          <m-switch v-model="enableExternalLinks" label-position="left" @change="handleExternalLinksChange" />
        </div>
        <p class="tlw-text-sm tlw-text-gray-600 tlw-mb-4">
          {{ $t('members-area.components.footer-config.drawer.external-links.description') }}
        </p>

        <transition name="fade">
          <div class="tlw-flex tlw-justify-between tlw-items-center tlw-mb-4" v-if="enableExternalLinks">
            <p class="tlw-text-sm tlw-text-gray-600">
              {{ $t('members-area.components.footer-config.drawer.external-links.count') }}
              {{ externalLinks.length }} / 8
            </p>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="enableExternalLinks" class="tlw-flex tlw-flex-col tlw-gap-4">
            <transition-group name="list" tag="div" class="tlw-flex tlw-flex-col tlw-gap-4">
              <div v-for="(link, index) in externalLinks" :key="index" class="tlw-flex tlw-flex-col tlw-gap-1">
                <div class="tlw-flex tlw-gap-2">
                  <Select
                    :options="iconOptions"
                    :value="link.icon"
                    @input="updateLinkIcon(index, $event)"
                    placeholder="--"
                  />
                  <div class="tlw-flex-1">
                    <hs-input
                      v-model="link.url"
                      placeholder="https://"
                      :state="!link.error ? null : false"
                      @input="clearLinkError(index)"
                    />
                  </div>
                  <MXButton variant="tertiary" @click="removeLink(index)">
                    <Icon name="trash-can" prefix="fal" :width="20" :height="20" class="icon-trash" />
                  </MXButton>
                </div>
              </div>
            </transition-group>

            <MXButton variant="secondary" class="tlw-w-full" @click="addNewLink" :disabled="externalLinks.length >= 8">
              {{ $t('members-area.components.footer-config.drawer.external-links.button') }}
            </MXButton>
            <transition v-if="externalLinks.length >= 8" name="fade">
              <p class="tlw-text-xs tlw-text-gray-500 tlw-mt-1 tlw-text-center">
                {{ $t('members-area.components.footer-config.drawer.external-links.max-links') }}
              </p>
            </transition>
          </div>
        </transition>
      </div>

      <template #footer>
        <div class="fixed bottom-0 left-0 right-0 tlw-flex tlw-justify-end tlw-gap-3">
          <MXButton variant="tertiary" @click="$emit('close')">
            {{ $t('members-area.components.footer-config.drawer.buttons.cancel') }}
          </MXButton>
          <MXButton variant="primary" @click="handleSave" :disabled="!hasChanges">
            {{ $t('members-area.components.footer-config.drawer.buttons.save') }}
          </MXButton>
        </div>
      </template>
    </Drawer>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Drawer from '@/components-new/Drawer.vue';
import MXButton from '@/shared/components/MXButton.vue';
import Icon from '@/components-new/Icon.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import TextArea from '@/shared/components/MXTextArea.vue';
import Select from '../Select.vue';

export default {
  name: 'FooterDrawer',
  components: {
    Drawer,
    MXButton,
    Icon,
    MSwitch,
    TextArea,
    Select,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      footerText: '',
      enableExternalLinks: false,
      externalLinks: [],
      showFooterText: false,
      footerTextError: false,
      originalValues: {
        footerText: '',
        enableExternalLinks: false,
        externalLinks: [],
        showFooterText: false,
      },
      iconOptions: [
        {
          label: 'Whatsapp',
          value: 'whatsapp',
          icon: 'whatsapp',
          prefix: 'fab',
        },
        {
          label: 'Youtube',
          value: 'youtube',
          icon: 'youtube',
          prefix: 'fab',
        },
        {
          label: 'Instagram',
          value: 'instagram',
          icon: 'instagram',
          prefix: 'fab',
        },
        {
          label: 'Facebook',
          value: 'facebook',
          icon: 'facebook-f',
          prefix: 'fab',
        },
        {
          label: 'Linkedin',
          value: 'linkedin',
          icon: 'linkedin-in',
          prefix: 'fab',
        },
        {
          label: 'Tiktok',
          value: 'tiktok',
          icon: 'tiktok',
          prefix: 'fab',
        },
        {
          label: 'Twitter',
          value: 'twitter',
          icon: 'x-twitter',
          prefix: 'fab',
        },
        {
          label: 'Email',
          value: 'email',
          icon: 'envelope',
          prefix: 'fas',
        },
        {
          label: 'Site',
          value: 'site',
          icon: 'globe',
          prefix: 'fas',
        },
        {
          label: 'Suporte',
          value: 'support',
          icon: 'headset',
          prefix: 'fas',
        },
      ],
      scrollbarWidth: 0,
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('membersArea', ['configs']),

    previewImage() {
      return this.selectedSchool?.logo || null;
    },

    hasChanges() {
      if (this.showFooterText !== this.originalValues.showFooterText) {
        if (this.showFooterText && !this.footerText.trim()) {
          return false;
        }
        return true;
      }

      if (this.showFooterText && this.footerText !== this.originalValues.footerText) {
        return this.footerText.trim() !== '';
      }

      if (this.enableExternalLinks !== this.originalValues.enableExternalLinks) {
        if (this.enableExternalLinks) {
          return this.hasValidLinks();
        }
        return true;
      }

      if (this.enableExternalLinks) {
        if (this.externalLinks.length !== this.originalValues.externalLinks.length) {
          return this.hasValidLinks();
        }

        for (let i = 0; i < this.externalLinks.length; i++) {
          const currentLink = this.externalLinks[i];
          const originalLink = this.originalValues.externalLinks[i];

          if (currentLink.url && this.isValidUrl(currentLink.url)) {
            if (!originalLink || currentLink.url !== originalLink.url || currentLink.icon !== originalLink.icon) {
              return true;
            }
          }
        }
      }

      return false;
    },
  },
  watch: {
    externalLinks: {
      handler() {},
      deep: true,
    },
    isOpen(newVal) {
      if (newVal) {
        this.preventScrollbarJump();
      } else {
        this.restoreScrollbar();
      }
    },
  },
  methods: {
    ...mapActions('membersArea', ['updateFooterConfig']),
    isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    },
    hasValidLinks() {
      return this.externalLinks.some(link => link.url && this.isValidUrl(link.url));
    },
    clearLinkError(index) {
      if (this.externalLinks[index]) {
        this.$set(this.externalLinks[index], 'error', false);
      }
    },
    clearFooterTextError() {
      this.footerTextError = false;
    },
    validateForm() {
      let isValid = true;

      if (this.showFooterText && !this.footerText.trim()) {
        this.footerTextError = true;
        isValid = false;
      } else {
        this.footerTextError = false;
      }

      if (this.enableExternalLinks && this.externalLinks.length > 0) {
        this.externalLinks.forEach((link, index) => {
          if (!this.isValidUrl(link.url)) {
            this.$set(this.externalLinks[index], 'error', true);
            isValid = false;
          } else {
            this.$set(this.externalLinks[index], 'error', false);
          }
        });
      }

      return { isValid };
    },
    handleSave() {
      const validation = this.validateForm();
      if (!validation.isValid) {
        return;
      }

      let data = {};

      if (this.showFooterText) data.custom_text = this.footerText;
      if (this.enableExternalLinks) {
        data.external_links = this.externalLinks.map(link => ({
          url: link.url,
          icon: link.icon || 'site',
        }));
      }
      data.show_custom_text = this.showFooterText;
      data.show_external_links = this.enableExternalLinks;
      data.deleted = false;

      this.updateFooterConfig({ data, schoolId: this.selectedSchool.id }).then(() => {
        this.$emit('saved');
        this.$emit('close');
      });
    },

    openGeneralSettings() {
      this.$emit('close');

      this.$nextTick(() => {
        this.$root.$emit('open-geral-config');
      });
    },

    addNewLink() {
      if (this.externalLinks.length >= 8) return;
      this.externalLinks.push({ url: '', icon: 'site' });
    },
    removeLink(index) {
      this.externalLinks.splice(index, 1);
    },
    updateLinkIcon(index, icon) {
      this.$set(this.externalLinks[index], 'icon', icon);
    },

    getScrollbarWidth() {
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll';
      document.body.appendChild(outer);

      const inner = document.createElement('div');
      outer.appendChild(inner);

      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      outer.parentNode.removeChild(outer);

      return scrollbarWidth;
    },

    preventScrollbarJump() {
      this.scrollbarWidth = this.getScrollbarWidth();

      const hasVerticalScrollbar = document.body.scrollHeight > window.innerHeight;

      if (hasVerticalScrollbar) {
        const scrollPosition = window.pageYOffset;

        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.scrollbarWidth}px`;

        window.scrollTo(0, scrollPosition);
      }
    },

    restoreScrollbar() {
      const scrollPosition = window.pageYOffset;
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
      window.scrollTo(0, scrollPosition);
    },

    handleExternalLinksChange(value) {
      if (value && this.externalLinks.length === 0) {
        this.addNewLink();
      }
    },
  },
  mounted() {
    this.externalLinks = this.configs.footer.external_links
      ? JSON.parse(JSON.stringify(this.configs.footer.external_links))
      : [];
    this.showFooterText = this.configs.footer.show_custom_text || false;
    this.footerText = this.configs.footer.custom_text || '';
    this.enableExternalLinks = this.configs.footer.show_external_links || false;

    this.originalValues = {
      footerText: this.footerText,
      enableExternalLinks: this.enableExternalLinks,
      externalLinks: JSON.parse(JSON.stringify(this.externalLinks)),
      showFooterText: this.showFooterText,
    };

    if (this.isOpen) {
      this.preventScrollbarJump();
    }
  },
  beforeDestroy() {
    this.restoreScrollbar();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .drawer {
  max-width: 568px;
  transition: all 0.3s ease;
  animation: slide-in 0.3s forwards;
}

/* Animação de entrada e saída */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

hr {
  margin: 24px 0;
}

/* Substituindo as animações de fade por animações de crescimento */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
  max-height: 500px;
  overflow: hidden;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s;
  max-height: 100px;
  overflow: hidden;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  max-height: 0;
  transform: scale(0.8);
}
.list-move {
  transition: transform 0.5s;
}

.logo-section {
  p {
    color: #525252;
    margin-bottom: 20px;
  }

  .logo-preview {
    width: 142px;
    height: 71px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #929292;

    .preview-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

.text-section {
  h3,
  p {
    margin-bottom: 0;
  }

  &__header {
    margin-bottom: 20px;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-bottom: 4px;
    }

    p {
      color: #262626;
      font-size: 14px;
    }
  }
}

.access-link {
  color: #7427f1;
  text-decoration: underline;
  cursor: pointer;
}

.icon-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 71px;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: 1px solid #bababa;

  p {
    margin: 0;
  }
}
.icon-trash {
  color: #db3939;
}

/deep/ .m-text-area {
  padding-bottom: 20px;
}

/deep/ .drawer__footer {
  padding: 24px 32px;
  border-top: 1px solid #cfcfcf;

  @media (max-width: 768px) {
    padding: 16px;
  }
}
</style>
