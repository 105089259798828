<template>
  <div class="custom-select-wrapper" v-click-outside="closeDropdown">
    <!-- Selected Value Display -->
    <div class="select-trigger" :class="{ 'select-trigger--open': isOpen }" @click="toggleDropdown">
      <span v-if="!selected" class="select-value">{{ placeholder }}</span>
      <span v-else class="select-value">
        <Icon :name="selected.icon" :prefix="selected.prefix" />
      </span>
      <hs-icon icon="chevron-down" class="select-arrow" :class="{ 'select-arrow--open': isOpen }" />
    </div>

    <!-- Dropdown Options -->
    <div v-if="isOpen" class="select-dropdown">
      <div
        v-for="option in options"
        :key="option.value"
        class="select-option"
        :class="{ 'select-option--selected': option.value === value }"
        @click="selectOption(option)"
      >
        <Icon :name="option.icon" :prefix="option.prefix" />
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';
export default {
  name: 'CustomSelect',

  props: {
    options: {
      type: Array,
      required: true,
      // Expected format: [{ label: 'Option 1', value: 'opt1' }, ...]
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
  },

  components: {
    Icon,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    selected() {
      const selected = this.options.find(opt => opt.value === this.value);
      return selected ? selected : '';
    },
  },

  directives: {
    clickOutside: {
      bind(el, binding) {
        el.clickOutsideEvent = event => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value();
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    closeDropdown() {
      this.isOpen = false;
    },

    selectOption(option) {
      this.$emit('input', option.value);
      this.closeDropdown();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select-wrapper {
  position: relative;
}

.select-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 8px 12px;
  background: white;
  border: 1px solid #bababa;
  border-radius: 4px;
  cursor: pointer;
  min-height: 38px;

  &--open {
    border-color: #7427f1;
  }
}

.select-value {
  font-size: 14px;
  color: #262626;
}

.select-arrow {
  transition: transform 0.2s ease;

  &--open {
    transform: rotate(180deg);
  }
}

.select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 250px;
  min-width: 180px;
  overflow-y: auto;
  z-index: 100;
  width: 100%;
}

.select-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #f3ebfa;
  }

  &--selected {
    background-color: #f3ebfa;
    color: #7427f1;
  }
}
</style>
