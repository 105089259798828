<template>
  <Accordeon
    :title="$t('members-area.components.banner-config.title')"
    :subtitle="$t('members-area.components.banner-config.subtitle')"
    icon="square-dashed-circle-plus"
    iconType="fal"
  >
    <div class="tlw-flex tlw-flex-col tlw-px-4 md:tlw-px-6 tlw-py-6">
      <div v-if="configs.header.banners.length === 0">
        <div class="tlw-w-full tlw-rounded-lg" style="height: 156px; background-color: #262626"></div>
        <h4 class="tlw-text-xl tlw-font-bold tlw-mt-5 tlw-text-center">
          {{ $t('members-area.components.banner-config.empty-state.title') }}
        </h4>
        <p class="tlw-text-sm tlw-mt-3 tlw-mb-5 tlw-text-neutral-500 tlw-text-center">
          {{ $t('members-area.components.banner-config.empty-state.description') }}
        </p>
      </div>
      <div v-else class="tlw-w-full">
        <span class="tlw-flex tlw-items-center tlw-gap-4 tlw-text-xs tlw-text-neutral-500 tlw-mb-3">
          {{ $t('members-area.components.banner-config.count', { count: configs.header.banners.length }) }}
          <span
            class="tlw-flex tlw-items-center tlw-gap-1 tlw-text-xs tlw-text-neutral-500"
            v-if="configs.header.banners.length === 3"
          >
            <Icon name="circle-exclamation" prefix="fal" :width="16" :height="16" />
            {{ $t('members-area.components.banner-config.max-banners') }}
          </span>
        </span>
        <div class="banner-list tlw-w-full">
          <draggable
            :model-value="configs.header.banners"
            @update:model-value="handleBannerOrderUpdate"
            handle=".drag-handle"
            @end="handleDragEnd"
            class="tlw-w-full"
          >
            <BannerPreview
              v-for="banner in configs.header.banners"
              :key="banner.id"
              :banner="banner"
              @edit="handleEditBanner"
              @delete="handleDeleteBanner"
            />
          </draggable>
        </div>
      </div>

      <div class="tlw-flex tlw-justify-center tlw-items-center tlw-gap-2 tlw-w-full">
        <MXButton
          v-if="configs.header.banners.length == 0"
          :icon="'fal fa-plus'"
          variant="primary"
          @click="openDrawer(null)"
        >
          {{ $t('members-area.components.banner-config.buttons.add') }}
        </MXButton>

        <div
          v-if="configs.header.banners.length !== 0"
          v-b-tooltip.hover.top="
            configs.header.banners.length >= 3 ? $t('members-area.components.banner-config.tooltip.max-banners') : null
          "
          class="tlw-w-full"
        >
          <MXButton
            :icon="'fal fa-plus'"
            variant="secondary"
            @click="openDrawer(null)"
            full-width
            :disabled="configs.header.banners.length >= 3"
          >
            {{ $t('members-area.components.banner-config.buttons.add') }}
          </MXButton>
        </div>
      </div>
    </div>

    <BannerDrawer v-if="isDrawerOpen" :banner="selectedBanner" @close="closeDrawer" />
  </Accordeon>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import MXButton from '@/shared/components/MXButton.vue';
import Accordeon from '../Accordeon.vue';
import BannerDrawer from '../banner/BannerDrawer.vue';
import BannerPreview from '../banner/BannerPreview.vue';
import draggable from 'vuedraggable';
import Icon from '@/components-new/Icon.vue';
import ToastHelper from '@/shared/helpers/toast';

export default {
  name: 'BannerConfig',

  components: {
    Accordeon,
    MXButton,
    BannerDrawer,
    BannerPreview,
    draggable,
    Icon,
  },

  data() {
    return {
      isDrawerOpen: false,
      selectedBanner: null,
    };
  },

  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('membersArea', ['configs']),
  },

  methods: {
    ...mapActions('membersArea', ['deleteBannerConfig', 'updateBannerConfig', 'markConfigAsChanged']),
    openDrawer(banner = null) {
      this.selectedBanner = banner;
      this.isDrawerOpen = true;
    },

    closeDrawer() {
      this.selectedBanner = null;
      this.isDrawerOpen = false;
    },

    handleEditBanner(banner) {
      this.openDrawer(banner);
    },

    async handleDeleteBanner(banner) {
      try {
        await this.deleteBannerConfig({ id: banner.id, schoolId: this.selectedSchool.id });
      } catch (error) {
        ToastHelper.dangerMessage('Erro ao excluir banner!');
      }
    },

    handleDragEnd(evt) {
      if (evt.oldIndex !== evt.newIndex) {
        const newBanners = [...this.configs.header.banners];
        const movedItem = newBanners.splice(evt.oldIndex, 1)[0];
        newBanners.splice(evt.newIndex, 0, movedItem);

        this.updateBannerOrder(newBanners);
      }
    },

    async updateBannerOrder(banners) {
      try {
        const updatedBanners = banners.map((banner, index) => ({
          ...banner,
          order: index,
        }));

        await this.updateBannerConfig({
          data: updatedBanners,
          schoolId: this.selectedSchool.id,
        });
      } catch (error) {
        ToastHelper.dangerMessage('Erro ao reordenar banners!');
      }
    },

    handleBannerOrderUpdate(value) {
      this.updateBannerOrder(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-list {
  margin-bottom: 1.5rem;
  overflow-x: hidden;
  max-width: 100%;

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

.sortable-ghost {
  opacity: 0.5;
  background: #f3f4f6;
}

.sortable-drag {
  opacity: 0.9;
}
</style>
