<template>
  <transition name="slide-fade" mode="out-in">
    <Drawer :title="drawerTitle" @close="$emit('close')" :without-padding="true">
      <div class="tlw-p-4">
        <div>
          <h3 class="drawer-section-title">{{ $t('members-area.components.banner-config.drawer.type-title') }}</h3>
          <div class="banner-type-options">
            <label class="banner-type-option" :class="{ 'banner-type-option--selected': bannerType === 'image' }">
              <div class="banner-type-content">
                <div class="tlw-relative tlw-w-4 tlw-h-4 tlw-flex tlw-items-center tlw-justify-center">
                  <input
                    type="radio"
                    name="bannerType"
                    value="image"
                    v-model="bannerType"
                    class="tlw-appearance-none tlw-w-4 tlw-h-4 tlw-rounded-full tlw-border tlw-border-gray-400 tlw-bg-white tlw-checked:bg-purple-600 tlw-checked:border-purple-600 tlw-focus:outline-none"
                    :class="bannerType === 'image' ? 'tlw-bg-purple-600 tlw-border-purple-600 tlw-scale-100' : ''"
                  />
                  <div
                    class="tlw-pointer-events-none tlw-absolute tlw-w-2 tlw-h-2 tlw-rounded-full tlw-transition-transform tlw-duration-200 tlw-ease-in-out"
                    :class="bannerType === 'image' ? 'tlw-bg-white tlw-scale-100' : 'tlw-scale-0'"
                  ></div>
                </div>
                <div class="banner-type-label-group">
                  <span class="banner-type-label tlw-font-bold">
                    <Icon name="image" prefix="fal" class="banner-type-icon" />
                    {{ $t('members-area.components.banner-config.drawer.type-image') }}
                  </span>
                </div>
              </div>
            </label>
          </div>
        </div>

        <!-- Banner Text Section -->
        <div class="text-section tlw-mt-6">
          <div class="text-section__header">
            <div class="tlw-flex tlw-items-center tlw-justify-between tlw-gap-3">
              <h3 class="tlw-text-sm tlw-font-bold">
                {{ $t('members-area.components.banner-config.drawer.text-title') }}
              </h3>
              <MSwitch v-model="showTexts" />
            </div>
            <p class="tlw-text-sm tlw-text-neutral-500 tlw-mb-0">
              {{ $t('members-area.components.banner-config.drawer.text-description') }}
            </p>
          </div>

          <div v-if="showTexts" class="tlw-flex tlw-flex-col tlw-gap-5">
            <MInput
              id="banner-title"
              v-model="bannerTitle"
              :label="$t('members-area.components.banner-config.drawer.input.label')"
              :placeholder="$t('members-area.components.banner-config.drawer.input.placeholder')"
              :maxLength="62"
              :hasError="titleError"
              @input="clearTitleError"
            />
            <p v-if="titleError" class="error-text">
              {{ $t('members-area.components.banner-config.drawer.input.error') }}
            </p>

            <MXTextArea
              id="banner-description"
              v-model="bannerDescription"
              :label="$t('members-area.components.banner-config.drawer.textarea.label')"
              :placeholder="$t('members-area.components.banner-config.drawer.textarea.placeholder')"
              :maxLength="200"
              height="120px"
              :hasError="descriptionError"
              @input="clearDescriptionError"
            />
            <p v-if="descriptionError" class="error-text">
              {{ $t('members-area.components.banner-config.drawer.textarea.error') }}
            </p>
          </div>
        </div>

        <!-- Divider -->
        <div class="divider"></div>

        <div class="links-section">
          <div class="links-section__header">
            <div class="tlw-flex tlw-items-center tlw-justify-between tlw-gap-3">
              <h3 class="tlw-text-sm tlw-font-bold">
                {{ $t('members-area.components.banner-config.drawer.links-section.links-title') }}
              </h3>
              <MSwitch v-model="showLinks" />
            </div>
            <p class="tlw-text-sm tlw-text-neutral-500">
              {{ $t('members-area.components.banner-config.drawer.links-section.links-description') }}
            </p>
          </div>

          <div v-if="showLinks" class="links-section__content">
            <div class="links-section__content-checkboxes">
              <MXInputCheckbox
                id="banner-link-button"
                v-model="showLinkButton"
                :label="$t('members-area.components.banner-config.drawer.links-section.checkboxes.link-button')"
                class="links-section__checkbox"
              />
              <MXInputCheckbox
                id="banner-link"
                v-model="showLink"
                :label="$t('members-area.components.banner-config.drawer.links-section.checkboxes.link')"
                class="links-section__checkbox"
              />
            </div>
            <div class="links-section__content-inputs">
              <MInput
                v-if="showLinkButton"
                id="banner-link-button-text"
                v-model="bannerLinkButtonText"
                :label="
                  $t('members-area.components.banner-config.drawer.links-section.content-inputs.link-button-text')
                "
                :maxLength="25"
                :placeholder="
                  $t(
                    'members-area.components.banner-config.drawer.links-section.content-inputs.link-button-text-placeholder'
                  )
                "
                :hasError="buttonTextError"
              />
              <MInput
                id="banner-link-url"
                v-model="bannerLinkUrl"
                :label="$t('members-area.components.banner-config.drawer.links-section.content-inputs.link-url')"
                :placeholder="
                  $t('members-area.components.banner-config.drawer.links-section.content-inputs.link-url-placeholder')
                "
                :hasError="linkUrlError"
              />
            </div>
            <div class="tlw-flex tlw-flex-col tlw-gap-2">
              <p v-if="buttonTextError" class="error-text">
                {{ $t('members-area.components.banner-config.drawer.links-section.content-inputs.button-text-error') }}
              </p>
              <p v-if="linkUrlError" class="error-text">
                {{ linkUrlError }}
              </p>
              <p v-if="checkboxError" class="error-text">
                {{ $t('members-area.components.banner-config.drawer.links-section.content-inputs.checkbox-error') }}
              </p>
            </div>
          </div>
        </div>

        <!-- Banner Image Section -->
        <div class="banner-image-section tlw-mt-6">
          <h3 class="drawer-section-title">
            {{ $t('members-area.components.banner-config.drawer.image-section.image-title') }}
          </h3>
          <p class="tlw-text-sm tlw-text-neutral-500 tlw-mb-8">
            {{ $t('members-area.components.banner-config.drawer.image-section.image-description') }}
          </p>

          <div class="tlw-flex tlw-flex-col banner-image-section__container">
            <!-- Desktop Version -->
            <div class="banner-upload-container" :class="{ 'banner-upload-container--error': showImageDesktopError }">
              <div class="banner-upload-container__mockup">
                <img
                  class="mockup-image"
                  :src="mockupDesktopImage"
                  :alt="$t('members-area.components.banner-config.drawer.image-section.desktop.image-alt')"
                />

                <div>
                  <img
                    v-if="desktopImagePreview"
                    :src="desktopImagePreview"
                    :alt="$t('members-area.components.banner-config.drawer.image-section.desktop.image-alt')"
                    class="tlw-w-full tlw-h-full tlw-object-cover tlw-rounded-lg"
                  />
                </div>
              </div>

              <div class="tlw-flex tlw-flex-col tlw-gap-3 info-section tlw-w-full">
                <div class="tlw-flex tlw-items-center tlw-gap-1">
                  <Icon name="display" prefix="fal" />
                  <span class="tlw-font-medium">
                    {{ $t('members-area.components.banner-config.drawer.image-section.desktop.version-title') }}
                  </span>
                </div>

                <div class="tlw-text-sm tlw-text-neutral-500 tlw-flex tlw-flex-col">
                  <p class="tlw-mb-0">
                    {{ $t('members-area.components.banner-config.drawer.image-section.desktop.format') }}
                  </p>
                  <p class="tlw-mb-0">
                    {{ $t('members-area.components.banner-config.drawer.image-section.desktop.image-size') }}
                  </p>
                  <p v-if="desktopImageError" class="error-text">{{ desktopImageError }}</p>
                </div>

                <div class="tlw-flex tlw-items-center tlw-gap-4">
                  <MXButton
                    variant="secondary"
                    :size="responsiveSize"
                    @click="triggerDesktopUpload"
                    :loading="isUploadingDesktop"
                    :full-width="true"
                  >
                    <Icon
                      v-if="isUploadingDesktop === false && !desktopImagePreview"
                      name="cloud-arrow-up"
                      prefix="fal"
                      :width="responsiveSize === 'large' ? '20' : '16'"
                      :height="responsiveSize === 'large' ? '20' : '16'"
                    />
                    {{ buttonLabelDesktop }}
                  </MXButton>

                  <MXButton
                    v-if="(desktopImage && !isUploadingDesktop) || (desktopImagePreview && !isUploadingDesktop)"
                    :icon="'far fa-trash-alt'"
                    variant="danger-tertiary"
                    :size="responsiveSize"
                    :full-width="true"
                    @click="removeDesktopImage"
                  >
                    {{ $t('members-area.components.banner-config.drawer.image-section.desktop.upload-button-remove') }}
                  </MXButton>
                </div>

                <input
                  type="file"
                  ref="desktopInput"
                  class="tlw-hidden"
                  accept="image/jpeg,image/jpg,image/png,image/gif"
                  @change="handleDesktopImageUpload"
                />
              </div>
            </div>

            <p v-if="showImageDesktopError" class="error-text">
              {{ $t('members-area.components.banner-config.drawer.image-section.desktop.text-error') }}
            </p>

            <div class="banner-upload-container" :class="{ 'banner-upload-container--error': showImageMobileError }">
              <div class="banner-upload-container__mockup">
                <img
                  class="mockup-image-mobile"
                  :src="mockupMobileImage"
                  :alt="$t('members-area.components.banner-config.drawer.image-section.mobile.image-alt')"
                />

                <div>
                  <img
                    v-if="mobileImagePreview"
                    :src="mobileImagePreview"
                    :alt="$t('members-area.components.banner-config.drawer.image-section.mobile.image-alt')"
                    class="tlw-w-full tlw-h-full tlw-object-cover tlw-rounded-lg mobile-image-preview"
                  />
                </div>
              </div>

              <div class="tlw-flex tlw-flex-col tlw-gap-3 info-section tlw-w-full">
                <div class="tlw-flex tlw-items-center tlw-gap-1">
                  <Icon name="mobile" prefix="fal" />
                  <span class="tlw-font-medium">
                    {{ $t('members-area.components.banner-config.drawer.image-section.mobile.version-title') }}
                  </span>
                </div>

                <div class="tlw-text-sm tlw-text-neutral-500 tlw-flex tlw-flex-col">
                  <p class="tlw-mb-0">
                    {{ $t('members-area.components.banner-config.drawer.image-section.mobile.format') }}
                  </p>
                  <p class="tlw-mb-0">
                    {{ $t('members-area.components.banner-config.drawer.image-section.mobile.image-size') }}
                  </p>
                  <p v-if="mobileImageError" class="error-text">{{ mobileImageError }}</p>
                </div>

                <div class="tlw-flex tlw-items-center tlw-gap-4">
                  <MXButton
                    variant="secondary"
                    :size="responsiveSize"
                    @click="triggerMobileUpload"
                    :loading="isUploadingMobile"
                    :full-width="true"
                  >
                    <Icon
                      v-if="isUploadingMobile === false && !mobileImagePreview"
                      name="cloud-arrow-up"
                      prefix="fal"
                      :width="responsiveSize === 'large' ? '20' : '16'"
                      :height="responsiveSize === 'large' ? '20' : '16'"
                    />
                    {{ buttonLabelMobile }}
                  </MXButton>
                  <MXButton
                    v-if="(mobileImage && !isUploadingMobile) || (mobileImagePreview && !isUploadingMobile)"
                    variant="danger-tertiary"
                    :size="responsiveSize"
                    :icon="'far fa-trash-alt'"
                    :full-width="true"
                    @click="removeMobileImage"
                  >
                    {{ $t('members-area.components.banner-config.drawer.image-section.mobile.upload-button-remove') }}
                  </MXButton>
                </div>

                <input
                  type="file"
                  ref="mobileInput"
                  class="tlw-hidden"
                  accept="image/jpeg,image/jpg,image/png,image/gif"
                  @change="handleMobileImageUpload"
                />
              </div>
            </div>

            <p v-if="showImageMobileError" class="error-text">
              {{ $t('members-area.components.banner-config.drawer.image-section.mobile.text-error') }}
            </p>
          </div>
        </div>

        <div class="accessibility-section">
          <div class="tlw-flex tlw-items-center tlw-gap-2">
            <h3 class="drawer-section-title tlw-mb-0">
              {{ $t('members-area.components.banner-config.drawer.accessibility-section.title') }}
            </h3>
          </div>

          <p class="tlw-text-sm tlw-text-neutral-500 tlw-mb-4">
            {{ $t('members-area.components.banner-config.drawer.accessibility-section.description') }}
          </p>

          <MXTextArea
            id="alt-text"
            v-model="altText"
            :placeholder="$t('members-area.components.banner-config.drawer.accessibility-section.input.placeholder')"
            :maxLength="300"
            height="120px"
          />
        </div>
      </div>

      <template #footer class="tlw-p-3 sm:tlw-p-4">
        <div class="drawer-footer">
          <MXButton variant="tertiary" @click="$emit('close')"> Cancelar </MXButton>
          <MXButton variant="primary" @click="handleSave" :disabled="!isValid || isSaving || isLoading">
            Salvar
          </MXButton>
        </div>
      </template>
    </Drawer>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Drawer from '@/components-new/Drawer.vue';
import MInput from '@/components/MInput.vue';
import MXTextArea from '@/shared/components/MXTextArea.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import Icon from '@/components-new/Icon.vue';
import ToastHelper from '@/shared/helpers/toast';
import MXButton from '@/shared/components/MXButton.vue';
import uploadService from '@/services/upload';
import MXInputCheckbox from '@/shared/components/MXInputCheckbox.vue';

import mockupDesktopBotaoTexto from '@/assets/images/MembersArea/mockup-desktop-botao-e-texto.png';
import mockupDesktopBotao from '@/assets/images/MembersArea/mockup-desktop-botao.png';
import mockupDesktopSemInfo from '@/assets/images/MembersArea/mockup-desktop-sem-info.png';
import mockupDesktopTexto from '@/assets/images/MembersArea/mockup-desktop-texto.png';
import mockupMobileBotaoTexto from '@/assets/images/MembersArea/mockup-mobile-botao-e-texto.png';
import mockupMobileBotao from '@/assets/images/MembersArea/mockup-mobile-botao.png';
import mockupMobileSemInfo from '@/assets/images/MembersArea/mockup-mobile-sem-info.png';
import mockupMobileTexto from '@/assets/images/MembersArea/mockup-mobile-texto.png';

export default {
  name: 'BannerDrawer',

  components: {
    Drawer,
    MInput,
    MXTextArea,
    MSwitch,
    Icon,
    MXButton,
    MXInputCheckbox,
  },

  props: {
    banner: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      bannerType: 'image',
      isSaving: false,
      isUploadingDesktop: false,
      isUploadingMobile: false,
      bannerId: null,
      showTexts: false,
      bannerTitle: '',
      bannerDescription: '',
      titleError: false,
      descriptionError: false,
      desktopImage: null,
      desktopImagePreview: null,
      mobileImage: null,
      mobileImagePreview: null,
      desktopImageError: null,
      mobileImageError: null,
      altText: '',
      showLinks: false,
      showLinkButton: false,
      showLink: false,
      bannerLinkUrl: '',
      bannerLinkButtonText: '',
      buttonTextError: false,
      linkUrlError: null,
      checkboxError: false,
      showImageDesktopError: false,
      showImageMobileError: false,
      scrollbarWidth: 0,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapState('membersArea', ['configs']),
    ...mapState('school', ['selectedSchool']),

    responsiveSize() {
      return this.windowWidth < 720 ? 'large' : 'small';
    },

    buttonLabelDesktop() {
      if (this.isUploadingDesktop)
        return this.$t('members-area.components.banner-config.drawer.image-section.desktop.upload-button-loading');

      return this.desktopImagePreview
        ? this.$t('members-area.components.banner-config.drawer.image-section.desktop.upload-button-change')
        : this.$t('members-area.components.banner-config.drawer.image-section.desktop.upload-button');
    },

    buttonLabelMobile() {
      if (this.isUploadingMobile)
        return this.$t('members-area.components.banner-config.drawer.image-section.mobile.upload-button-loading');

      return this.mobileImagePreview
        ? this.$t('members-area.components.banner-config.drawer.image-section.mobile.upload-button-change')
        : this.$t('members-area.components.banner-config.drawer.image-section.mobile.upload-button');
    },

    mockupDesktopImage() {
      const flag = (this.showTexts ? 4 : 0) + (this.showLinkButton ? 2 : 0) + (this.showLink ? 1 : 0);

      switch (flag) {
        case 0:
          return mockupDesktopSemInfo;

        case 1:
          return mockupDesktopTexto;

        case 2:
          return mockupDesktopBotao;

        case 3:
          return mockupDesktopBotao;

        case 4:
          return mockupDesktopTexto;

        case 5:
          return mockupDesktopTexto;

        case 6:
          return mockupDesktopBotaoTexto;

        case 7:
          return mockupDesktopBotaoTexto;

        default:
          return mockupDesktopSemInfo;
      }
    },

    mockupMobileImage() {
      const flag = (this.showTexts ? 4 : 0) + (this.showLinkButton ? 2 : 0) + (this.showLink ? 1 : 0);

      switch (flag) {
        case 0:
          return mockupMobileSemInfo;

        case 1:
          return mockupMobileTexto;

        case 2:
          return mockupMobileBotao;

        case 3:
          return mockupMobileBotao;

        case 4:
          return mockupMobileTexto;

        case 5:
          return mockupMobileTexto;

        case 6:
          return mockupMobileBotaoTexto;

        case 7:
          return mockupMobileBotaoTexto;

        default:
          return mockupMobileSemInfo;
      }
    },

    banners() {
      return this.configs.header?.banners || [];
    },

    drawerTitle() {
      return this.banner
        ? this.$t('members-area.components.banner-config.drawer.edit-title')
        : this.$t('members-area.components.banner-config.drawer.add-title');
    },

    canAddBanner() {
      if (this.isEditing) return true;
      return this.banners.length < 3;
    },

    nextBannerOrder() {
      if (!this.banners.length) return 0;

      // Find the highest order number and add 1
      const maxOrder = Math.max(...this.banners.map(banner => banner.order));
      return maxOrder + 1;
    },

    isLoading() {
      return this.isUploadingDesktop || this.isUploadingMobile;
    },

    isValid() {
      if (this.showTexts) {
        if (!this.bannerTitle.trim()) return false;

        if (this.bannerDescription.trim() && !this.bannerTitle.trim()) return false;
      }

      if (!this.desktopImagePreview || !this.mobileImagePreview) return false;

      // Verificar se há erros de imagem
      if (this.showImageDesktopError || this.showImageMobileError) return false;

      return this.canAddBanner && this.bannerType;
    },

    isEditing() {
      return !!this.bannerId;
    },

    hasAnyImage() {
      return !!(this.desktopImagePreview || this.mobileImagePreview);
    },
  },

  methods: {
    ...mapActions('membersArea', ['updateBannerConfig']),

    clearTitleError() {
      this.titleError = false;
      if (!this.bannerTitle.trim()) {
        this.bannerDescription = '';
      }
    },

    clearDescriptionError() {
      if (this.bannerTitle.trim()) {
        this.descriptionError = false;
      }
    },

    validateLinks() {
      this.buttonTextError = false;
      this.linkUrlError = '';
      this.checkboxError = false;

      if (!this.showLinks) return true;

      // Validate at least one checkbox is checked
      if (!this.showLinkButton && !this.showLink) {
        this.checkboxError = true;
        return false;
      }

      // Validate button text if button is enabled
      if (this.showLinkButton && !this.bannerLinkButtonText.trim()) {
        this.buttonTextError = true;
        return false;
      }

      // Validate URL if links are enabled
      if (this.showLinks && this.bannerLinkUrl) {
        try {
          new URL(this.bannerLinkUrl);
        } catch (e) {
          this.linkUrlError = this.$t(
            'members-area.components.banner-config.drawer.links-section.content-inputs.link-url-error'
          );
          return false;
        }
      } else if (this.showLinks) {
        this.linkUrlError = this.$t(
          'members-area.components.banner-config.drawer.links-section.content-inputs.link-url-error-required'
        );
        return false;
      }

      return true;
    },

    clearImageErrors() {
      // Limpar erros de imagem
      if (this.desktopImagePreview) {
        this.showImageDesktopError = false;
        this.desktopImageError = null;
      }

      if (this.mobileImagePreview) {
        this.showImageMobileError = false;
        this.mobileImageError = null;
      }
    },

    validateForm() {
      if (this.showTexts) {
        this.titleError = !this.bannerTitle.trim();

        if (this.bannerTitle.trim()) {
          this.descriptionError = false;
        } else if (this.bannerDescription.trim()) {
          this.descriptionError = true;
        }

        if (this.titleError || this.descriptionError) return false;
      }

      // Limpar erros anteriores
      this.clearImageErrors();

      if (!this.desktopImagePreview) {
        this.desktopImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.desktop.text-error-required'
        );
        this.showImageDesktopError = true;
        return false;
      }

      if (!this.mobileImagePreview) {
        this.mobileImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.mobile.text-error-required'
        );
        this.showImageMobileError = true;
        return false;
      }

      return this.validateLinks();
    },

    async handleSave() {
      if (!this.validateForm()) return;

      if (!this.canAddBanner) {
        ToastHelper.dangerMessage(this.$t('members-area.components.banner-config.drawer.danger-message-limit'));
        return;
      }

      if (!this.desktopImagePreview) {
        this.desktopImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.desktop.text-error-required'
        );
        this.showImageDesktopError = true;
        ToastHelper.dangerMessage(
          this.$t('members-area.components.banner-config.drawer.image-section.desktop.danger-message-required')
        );
        return;
      }

      if (!this.mobileImagePreview) {
        this.mobileImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.mobile.text-error-required'
        );
        this.showImageMobileError = true;
        ToastHelper.dangerMessage(
          this.$t('members-area.components.banner-config.drawer.image-section.mobile.danger-message-required')
        );
        return;
      }

      try {
        this.isSaving = true;
        const bannerData = {
          active: true,
          alt_text: this.altText || null,
          description: this.showTexts ? this.bannerDescription : null,
          image_desktop_url: this.desktopImagePreview,
          image_mobile_url: this.mobileImagePreview,
          kind: this.bannerType,
          link_button_text: this.showLinks && this.showLinkButton ? this.bannerLinkButtonText : null,
          link_url: this.showLinks ? this.bannerLinkUrl : null,
          order: this.isEditing ? this.banner.order : this.nextBannerOrder,
          show_link: this.showLinks,
          show_link_button: this.showLinks && this.showLinkButton,
          show_link_text: this.showLinks && this.showLink,
          show_texts: this.showTexts,
          title: this.showTexts ? this.bannerTitle : null,
        };

        if (this.bannerId) bannerData.id = this.bannerId;

        const updatedBanners = this.isEditing
          ? this.banners.map(banner => (banner.id === this.bannerId ? bannerData : banner))
          : [...this.banners, bannerData];

        await this.updateBannerConfig({
          data: updatedBanners,
          schoolId: this.selectedSchool.id,
        });

        ToastHelper.successMessage(
          this.isEditing
            ? this.$t('members-area.components.banner-config.drawer.success-message-update')
            : this.$t('members-area.components.banner-config.drawer.success-message-add')
        );
        this.$emit('close');
      } catch (error) {
        ToastHelper.dangerMessage(
          this.isEditing
            ? this.$t('members-area.components.banner-config.drawer.banner-error-message')
            : this.$t('members-area.components.banner-config.drawer.banner-success-message')
        );
      } finally {
        this.isSaving = false;
      }
    },

    initializeFromBanner(banner) {
      if (!banner) return;
      this.bannerId = banner.id;
      this.bannerType = banner.kind;
      this.showTexts = banner.show_texts;
      this.bannerTitle = banner.title || '';
      this.bannerDescription = banner.description || '';
      this.desktopImagePreview = banner.image_desktop_url;
      this.mobileImagePreview = banner.image_mobile_url;
      this.altText = banner.alt_text || '';
      // Initialize link related data
      this.showLinks = banner.show_link;
      this.showLinkButton = banner.show_link_button;
      this.showLink = banner.show_link_text;
      this.bannerLinkButtonText = banner.link_button_text || '';
      this.bannerLinkUrl = banner.link_url || '';

      if (!this.desktopImagePreview) {
        this.desktopImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.desktop.text-error-required'
        );
        this.showImageDesktopError = true;
      } else {
        this.showImageDesktopError = false;
        this.desktopImageError = null;
      }

      if (!this.mobileImagePreview) {
        this.mobileImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.mobile.text-error-required'
        );
        this.showImageMobileError = true;
      } else {
        this.showImageMobileError = false;
        this.mobileImageError = null;
      }
    },

    triggerDesktopUpload() {
      this.$refs.desktopInput.click();
    },

    triggerMobileUpload() {
      this.$refs.mobileInput.click();
    },

    async uploadBannerImage(file) {
      try {
        // Obter as chaves de upload da AWS
        const { data } = await uploadService.getAwsKeys(file.type, this.selectedSchool.id);

        // Fazer o upload do arquivo para a AWS
        const imageUrl = await uploadService.upload(data.url, data.fields, file);

        return imageUrl;
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('members-area.components.banner-config.drawer.upload-error-message'));
        throw error;
      }
    },

    async handleDesktopImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Reset error states
      this.desktopImageError = null;
      this.showImageDesktopError = false; // Clear image error when uploading

      // Check file type
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        this.desktopImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.desktop.valid-types'
        );
        this.showImageDesktopError = true;
        event.target.value = '';
        return;
      }

      // Check file size (2MB = 2097152 bytes)
      if (file.size > 2097152) {
        this.desktopImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.desktop.valid-size'
        );
        this.showImageDesktopError = true;
        event.target.value = '';
        return;
      }

      try {
        this.isUploadingDesktop = true;
        const imageUrl = await this.uploadBannerImage(file);
        this.desktopImage = file;
        this.desktopImagePreview = imageUrl;
        this.showImageDesktopError = false;
        this.desktopImageError = null;

        if (!this.mobileImagePreview) {
          this.mobileImageError = this.$t(
            'members-area.components.banner-config.drawer.image-section.mobile.danger-message-required'
          );
          this.showImageMobileError = true;
          ToastHelper.successMessage(
            this.$t('members-area.components.banner-config.drawer.image-section.desktop.success-message-only')
          );
        } else {
          ToastHelper.successMessage(
            this.$t('members-area.components.banner-config.drawer.image-section.desktop.success-message')
          );
        }
      } catch (error) {
        this.desktopImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.desktop.error-message'
        );
        this.showImageDesktopError = true;
      } finally {
        this.isUploadingDesktop = false;
        event.target.value = '';
      }
    },

    async handleMobileImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Reset error states
      this.mobileImageError = null;
      this.showImageMobileError = false; // Clear image error when uploading

      // Check file type
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/'];
      if (!validTypes.includes(file.type)) {
        this.mobileImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.mobile.valid-types'
        );
        this.showImageMobileError = true;
        event.target.value = '';
        return;
      }

      // Check file size (2MB = 2097152 bytes)
      if (file.size > 2097152) {
        this.mobileImageError = this.$t('members-area.components.banner-config.drawer.image-section.mobile.valid-size');
        this.showImageMobileError = true;
        event.target.value = '';
        return;
      }

      try {
        this.isUploadingMobile = true;
        const imageUrl = await this.uploadBannerImage(file);
        this.mobileImage = file;
        this.mobileImagePreview = imageUrl;
        this.showImageMobileError = false;
        this.mobileImageError = null;

        if (!this.desktopImagePreview) {
          this.desktopImageError = this.$t(
            'members-area.components.banner-config.drawer.image-section.desktop.danger-message-required'
          );
          this.showImageDesktopError = true;
          ToastHelper.successMessage(
            this.$t('members-area.components.banner-config.drawer.image-section.mobile.success-message-only')
          );
        } else {
          ToastHelper.successMessage(
            this.$t('members-area.components.banner-config.drawer.image-section.mobile.success-message')
          );
        }
      } catch (error) {
        this.mobileImageError = this.$t(
          'members-area.components.banner-config.drawer.image-section.mobile.error-message'
        );
        this.showImageMobileError = true;
      } finally {
        this.isUploadingMobile = false;
        event.target.value = '';
      }
    },

    removeDesktopImage() {
      this.desktopImage = null;
      this.desktopImagePreview = null;
      this.desktopImageError = this.$t(
        'members-area.components.banner-config.drawer.image-section.desktop.danger-message-required'
      );
      this.$refs.desktopInput.value = '';
      this.showImageDesktopError = true;

      if (this.mobileImagePreview) {
        ToastHelper.warningMessage(this.$t('members-area.components.banner-config.drawer.warning-message'));
      }
    },

    removeMobileImage() {
      this.mobileImage = null;
      this.mobileImagePreview = null;
      this.mobileImageError = this.$t(
        'members-area.components.banner-config.drawer.image-section.mobile.danger-message-required'
      );
      this.$refs.mobileInput.value = '';
      this.showImageMobileError = true;

      if (this.desktopImagePreview) {
        ToastHelper.warningMessage(this.$t('members-area.components.banner-config.drawer.warning-message'));
      }
    },

    // Método para calcular a largura da barra de rolagem
    getScrollbarWidth() {
      // Cria um elemento div para medir a largura da barra de rolagem
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll';
      document.body.appendChild(outer);

      // Cria um elemento interno
      const inner = document.createElement('div');
      outer.appendChild(inner);

      // Calcula a diferença entre a largura do elemento externo e interno
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      // Remove os elementos temporários
      outer.parentNode.removeChild(outer);

      return scrollbarWidth;
    },

    // Método para prevenir o "salto" causado pela barra de rolagem
    preventScrollbarJump() {
      // Calcula a largura da barra de rolagem
      this.scrollbarWidth = this.getScrollbarWidth();

      // Verifica se a página tem barra de rolagem
      const hasVerticalScrollbar = document.body.scrollHeight > window.innerHeight;

      if (hasVerticalScrollbar) {
        // Salva o scroll atual
        const scrollPosition = window.pageYOffset;

        // Adiciona padding-right ao body para compensar a largura da barra de rolagem
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.scrollbarWidth}px`;

        // Mantém a posição de scroll
        window.scrollTo(0, scrollPosition);
      }
    },

    // Método para restaurar o comportamento normal da barra de rolagem
    restoreScrollbar() {
      const scrollPosition = window.pageYOffset;
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
      window.scrollTo(0, scrollPosition);
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  created() {
    if (this.banner) {
      this.initializeFromBanner(this.banner);
    }
    if (!this.isEditing && !this.canAddBanner) {
      ToastHelper.dangerMessage(this.$t('members-area.components.banner-config.drawer.danger-message-limit'));
      this.$emit('close');
    }

    // Aplica a prevenção do salto quando o componente é criado
    this.$nextTick(() => {
      this.preventScrollbarJump();
    });

    window.addEventListener('resize', this.handleResize);
  },

  watch: {
    showLinks(newValue) {
      if (!newValue) {
        // Reset link related errors when links are disabled
        this.buttonTextError = false;
        this.linkUrlError = null;
        this.checkboxError = false;
      }
    },

    desktopImagePreview(newValue) {
      if (newValue) {
        this.showImageDesktopError = false;
        this.desktopImageError = null;
      }
    },

    mobileImagePreview(newValue) {
      if (newValue) {
        this.showImageMobileError = false;
        this.mobileImageError = null;
      }
    },
  },

  beforeDestroy() {
    if (this.desktopImagePreview) URL.revokeObjectURL(this.desktopImagePreview);
    if (this.mobileImagePreview) URL.revokeObjectURL(this.mobileImagePreview);

    // Restaura a barra de rolagem quando o componente é destruído
    this.restoreScrollbar();

    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.drawer-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.drawer-section-title {
  font-size: 1rem;
  font-weight: 600;
  color: #262626;
  margin-bottom: 1rem;
}

.banner-type-options {
  display: flex;
  gap: 1rem;
}

.banner-type-option {
  flex: 1;
  cursor: pointer;
  border: 2px solid #bababa;
  border-radius: 8px;
  padding: 8px;
  transition: all 0.2s ease;

  &:hover:not(&--disabled) {
    border-color: #9333ea;
  }

  &--selected:not(&--disabled) {
    border-color: #9333ea;
  }

  &--disabled {
    cursor: not-allowed;
    background-color: #f3f4f6;
    border-color: #e5e7eb;
    opacity: 0.8;

    &:hover {
      border-color: #e5e7eb;
    }

    .banner-type-icon,
    .banner-type-label {
      color: #9ca3af;
    }
  }
}

.banner-type-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    border-color: #bababa;
  }
}

.banner-type-icon {
  font-size: 1.25rem;
  color: #6b46c1;
  margin-right: 0.5rem;
}

.banner-type-label {
  font-size: 0.875rem;
  color: #262626;
  display: flex;
  align-items: center;
}

.banner-type-label-group {
  display: flex;
  flex-direction: column;
}

.text-section {
  &__header {
    margin-bottom: 1rem;
  }
}

.error-text {
  color: #db3939;
  font-size: 12px;
  margin-top: 4px;
}

.banner-image-section {
  border-top: 1px solid #e5e5e5;
  padding-top: 1.5rem;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and(min-width: 720px) {
      gap: 1rem;
    }
  }
}

.banner-upload-container {
  @apply tlw-flex tlw-flex-col tlw-gap-5 tlw-border md:tlw-p-2 tlw-border-transparent tlw-rounded-lg;
  transition: border-color 0.2s ease;
  position: relative;
  font-size: 12px;

  p {
    font-size: 12px;
  }

  @media screen and(min-width: 720px) {
    flex-direction: row;
    font-size: 14px;

    p {
      font-size: 14px;
    }
  }

  .info-section {
    padding-inline: 0.125rem;

    @media screen and(min-width: 720px) {
      padding-inline: 0rem;
    }
  }

  &__mockup {
    position: relative;

    .mockup-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 330px;
      height: 100%;
      object-fit: cover;
      margin: 0 auto;
      object-position: bottom;

      @media screen and(min-width: 720px) {
        // @apply tlw-max-h-[190px] tlw-object-fill;
      }
    }

    .mockup-image-mobile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 290px;
      height: 100%;
      object-fit: contain;
      object-position: top;
      margin: 0 auto;
    }

    > div {
      @apply tlw-flex tlw-items-center tlw-justify-center tlw-h-[190px] tlw-bg-gray-200 tlw-rounded-lg tlw-overflow-hidden;

      width: 100%;

      @media screen and(min-width: 720px) {
        min-width: 315px;
      }

      img {
        margin-top: -1.75rem;
        width: 272px;
        height: 128px;
        object-fit: fill;
        aspect-ratio: 4 / 3;

        @media screen and(min-width: 720px) {
          margin-top: -1.25rem;
          width: 260px;
          height: 128px;
          object-fit: fill;
        }
      }

      .mobile-image-preview {
        width: 86px;

        height: 190px;
        max-height: 160px;
        object-fit: cover;
        object-position: top;
        margin-top: -0.5rem;

        @media screen and(min-width: 720px) {
          object-fit: fill;
          height: 160px;
          margin-top: -0.125rem;
        }
      }
    }
  }
}

.accessibility-section {
  padding-top: 2rem;
}

.divider {
  border-top: 1px solid #cfcfcf;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.links-section {
  &__header {
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &-checkboxes {
      display: flex;
      gap: 1rem;
    }
    &-inputs {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .m-input {
        width: 100%;
      }
    }
  }

  &__checkbox {
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #bababa;
  }
}

/* Animação de entrada e saída */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

/deep/ .drawer {
  max-width: 632px;
  transition: all 0.3s ease;
  animation: slide-in 0.3s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
