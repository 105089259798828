<template>
  <div>
    <b-modal
      id="remove-logo-modal"
      content-class="teste"
      body-class="remove-logo-modal-body"
      @ok="confirmRemove"
      @cancel="cancelRemove"
      centered
      hide-header
      hide-footer
    >
      <div class="remove-logo-modal__content">
        <button class="close-button" @click="cancelRemove">
          <span>&times;</span>
        </button>
        <hs-icon :size="47" icon="info-circle" />
        <h3>Deseja remover sua logo?</h3>
        <p>
          Ao modificar a logo, você impactará a
          <strong> Área de Membros, a Página de Vendas, os E-mails e a Tela de Login. </strong>
          <br /><br />
          A alteração será automaticamente publicada assim que for realizada.
          <br /><br />
          Deseja prosseguir com essa mudança?
        </p>

        <div>
          <MButton size="sm" variant="link" @click="cancelRemove">Cancelar</MButton>
          <MButton size="sm" variant="critical" @click="confirmRemove">Remover Logo</MButton>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  name: 'RemoveLogoModal',
  components: { MButton },
  methods: {
    confirmRemove() {
      this.$emit('confirm');
    },
    cancelRemove() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.remove-logo-modal-body {
  width: 360px;
  display: flex;
  padding: 29px;

  @media screen and (max-width: 360px) {
    width: 100%;
  }
}

.remove-logo-modal__content {
  display: flex;
  flex-direction: column;
  position: relative;

  .close-button {
    position: absolute;
    top: -15px;
    right: -15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #000;
    padding: 0;
    line-height: 1;

    &:hover {
      opacity: 0.7;
    }

    &:focus {
      outline: none;
    }
  }

  i {
    text-align: center;
    color: #db3939;
    margin-bottom: 20px;
  }

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #000;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 32px;
    text-align: center;
    color: #262626;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 21px;

    button {
      width: 100%;

      &:first-child {
        text-decoration: underline;
      }

      &:last-child {
        background-color: #db3939;
        color: #fff;
      }
    }
  }
}
/deep/.modal-content {
  width: unset;
}
/deep/.modal-dialog {
  max-width: 341px;
  margin: 0 auto !important;
}
</style>
