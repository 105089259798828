<template>
  <Accordeon
    :title="$t('members-area.components.section-config.title')"
    :subtitle="$t('members-area.components.section-config.subtitle')"
    icon="rectangle-vertical-history"
    iconType="fal"
  >
    <div>
      <div v-for="section in configs.sections" :key="section.kind">
        <MyProductsSection v-if="section.kind === 'school_products'" />
      </div>
    </div>
  </Accordeon>
</template>

<script>
import Accordeon from '../Accordeon.vue';
import MyProductsSection from '../sections/MyProductsSection.vue';
import { mapState } from 'vuex';

export default {
  name: 'SectionConfig',
  components: {
    Accordeon,
    MyProductsSection,
  },

  computed: {
    ...mapState('membersArea', ['configs']),
  },
};
</script>
<style lang="scss" scoped></style>
