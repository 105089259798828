<template>
  <PageLayout>
    <PageHeader
      :title="$t('members-area.index.page-header.title')"
      :sub-title="$t('members-area.index.page-header.subtitle')"
    >
      <template #actions>
        <div class="tlw-flex tlw-gap-5 tlw-w-full">
          <MXButton variant="primary" id="publish-button" :disabled="!hasChanges" @click="handlePublish">
            {{ $t('members-area.index.buttons.publish') }}
          </MXButton>
          <MXButton variant="secondary" id="preview-button"> {{ $t('members-area.index.buttons.preview') }} </MXButton>
        </div>
      </template>
    </PageHeader>
    <div v-if="!isLoading" class="tlw-flex md:tlw-gap-5 config-wrapper">
      <div class="accordion-wrapper tlw-flex-1 tlw-flex tlw-flex-col tlw-gap-2">
        <GeralConfig />
        <BannerConfig />
        <SectionConfig />
        <FooterConfig />
      </div>
    </div>
    <div class="save-button-wrapper tlw-fixed tlw-bottom-0 tlw-left-0 tlw-right-0 tlw-p-4 md:tlw-p-8 tlw-bg-white">
      <div class="tlw-flex tlw-gap-5">
        <MXButton variant="primary" id="save-button">{{ $t('members-area.index.buttons.publish-changes') }}</MXButton>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from '@/layout/PageLayout.vue';
import GeralConfig from './components/config/GeralConfig.vue';
import SectionConfig from './components/config/SectionConfig.vue';
import FooterConfig from './components/config/FooterConfig.vue';
import BannerConfig from './components/config/BannerConfig.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import MXButton from '@/shared/components/MXButton.vue';
import ToastHelper from '@/shared/helpers/toast';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  components: {
    PageLayout,
    GeralConfig,
    SectionConfig,
    FooterConfig,
    BannerConfig,
    PageHeader,
    MXButton,
  },
  name: 'MembersArea',
  data() {
    return {
      selectedLayout: 'grid',
      isLoading: false,
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapGetters('membersArea', ['hasChanges', 'activeMembersAreaConfig']),
  },
  async created() {
    this.isLoading = true;
    try {
      await this.setInitialConfig(this.selectedSchool.id);
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('members-area.index.toast.error.load-config'));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('membersArea', ['saveConfigs', 'setInitialConfig']),

    async handlePublish() {
      try {
        await this.saveConfigs(this.selectedSchool.id);
        ToastHelper.successMessage(this.$t('members-area.index.toast.success.publish'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('members-area.index.toast.error.publish'));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#publish-button {
  @media screen and (max-width: 768px) {
    @apply tlw-hidden;
  }
}

#preview-button {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .save-button-wrapper {
    @apply tlw-hidden;
  }
}

#save-button {
  width: 100%;
}

.accordion-wrapper {
  div:last-child {
    margin-bottom: 68px;
  }
}

.config-wrapper {
  @apply tlw-flex-1;
  margin: 0 auto;
  padding-top: 2.625rem;
  padding-bottom: 2.625rem;
  padding-inline: 1.5rem;

  @media screen and (min-width: 1280px) {
    max-width: 830px;
    padding-inline: 0;
  }

  @media screen and (min-width: 1366px) {
    max-width: 900px;
    padding-inline: 0;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1086px;
    padding-inline: 0;
  }
}
</style>
