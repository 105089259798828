var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({class:[
    'mx-button',
    ("mx-button--" + _vm.variant),
    ("mx-button--" + _vm.size),
    ("mx-button--" + _vm.behavior),
    { 'mx-button--loading': _vm.loading },
    { 'mx-button--disabled-only': _vm.loading && !_vm.disabled },
    { 'tlw-w-full': _vm.fullWidth } ],attrs:{"disabled":_vm.isDisabled}},'button',[_vm.$attrs, _vm.$props],false),_vm.$listeners),[_c('div',{staticClass:"mx-button__content-wrapper"},[(_vm.loading)?_c('div',{staticClass:"spinner-loader"}):(_vm.icon)?_c('i',{staticClass:"mx-button__icon",class:_vm.icon}):_vm._e(),_vm._v(" "),(_vm.$slots.default)?_c('span',{staticClass:"mx-button__text"},[_vm._t("default")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }