<template>
  <div class="m-tooltip-wrapper">
    <div class="m-tooltip-trigger" @click="toggleTooltip" ref="trigger">
      <slot name="trigger" />
    </div>

    <transition name="fade">
      <div v-if="isOpen" class="m-tooltip-portal">
        <div class="m-tooltip" :class="{ 'is-active': isOpen }" :style="tooltipStyle" ref="tooltip">
          <div class="m-tooltip__image">
            <img :src="image" :alt="title" />
          </div>
          <div class="m-tooltip__content">
            <h6 class="m-tooltip__title">{{ title }}</h6>
            <p class="m-tooltip__subtitle">{{ description }}</p>
            <div class="m-tooltip__buttons">
              <m-button
                variant="link"
                :label="primaryButtonLabel"
                class="m-tooltip__button"
                @click="handlePrimaryClick"
              />
              <m-button
                variant="primary"
                :label="secondaryButtonLabel"
                class="m-tooltip__button"
                @click="handleSecondaryClick"
                icon="arrow-up-right-from-square"
                iconType="fal"
                newIcon
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MButton from './MButton.vue';

export default {
  name: 'MTooltip',

  components: {
    MButton,
  },

  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    primaryButtonLabel: {
      type: String,
      default: 'Continuar',
    },
    secondaryButtonLabel: {
      type: String,
      default: 'Ler mais',
    },
  },

  data() {
    return {
      isOpen: false,
      tooltipStyle: null,
    };
  },

  methods: {
    calculatePosition() {
      const trigger = this.$refs.trigger;
      const triggerRect = trigger.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const tooltipWidth = 320;
      const tooltipHeight = 400; // Approximate height of tooltip

      // Position to the right by default
      let left = triggerRect.right + 8;
      let top = triggerRect.top;

      // If tooltip would overflow right side, position to the left
      if (left + tooltipWidth > windowWidth) {
        left = triggerRect.left - tooltipWidth - 8;
      }

      // If tooltip would overflow left side, center horizontally with trigger
      if (left < 0) {
        left = Math.max(8, triggerRect.left + (triggerRect.width - tooltipWidth) / 2);
      }

      // If tooltip would overflow bottom, adjust top position
      if (top + tooltipHeight > windowHeight) {
        top = Math.max(8, windowHeight - tooltipHeight - 8);
      }

      // If tooltip would overflow top, adjust top position
      if (top < 0) {
        top = 8;
      }

      return {
        position: 'fixed',
        top: `${top}px`,
        left: `${left}px`,
        transform: 'none',
        opacity: 1,
      };
    },

    toggleTooltip() {
      if (!this.isOpen) {
        // Calculate position before showing tooltip
        this.tooltipStyle = this.calculatePosition();
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    },

    updatePosition() {
      if (!this.isOpen) return;
      this.tooltipStyle = this.calculatePosition();
    },

    handlePrimaryClick() {
      this.$emit('primary-click');
      this.isOpen = false;
    },
    handleSecondaryClick() {
      this.$emit('secondary-click');
      this.isOpen = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.isOpen) {
        this.isOpen = false;
      }
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('scroll', this.updatePosition);
    window.addEventListener('resize', this.updatePosition);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('scroll', this.updatePosition);
    window.removeEventListener('resize', this.updatePosition);
  },
};
</script>

<style lang="scss" scoped>
.m-tooltip {
  &-wrapper {
    position: relative;
    display: inline-block;
  }

  &-trigger {
    cursor: pointer;
  }

  &-portal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 9999;
  }
}

.m-tooltip {
  padding: 16px;
  position: fixed;
  background: rgba(38, 38, 38, 0.95);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  width: 303px;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);
  pointer-events: auto;
  transition: all 0.2s ease;

  &__image {
    width: 100%;
    height: 162px;
    background: rgba(26, 26, 26, 0.5);
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.9;
    }
  }

  &__content {
    margin-top: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px 0;
    color: #ffffff;
    text-align: start;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 21px;
    color: #bababa;
    margin-bottom: 20px;
    font-weight: 400;
    text-align: start;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  &__button {
    height: 31px;
    font-size: 14px;
    padding: 8px 16px;

    &:first-child {
      color: #ffffff;
      text-decoration: underline;
    }
  }

  // Add initial opacity of 0 to prevent flash
  opacity: 0;

  &.is-active {
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-4px);
}
</style>
