export default {
  data() {
    return {
      iconMapping: {
        whatsapp: { icon: 'whatsapp', prefix: 'fab' },
        youtube: { icon: 'youtube', prefix: 'fab' },
        instagram: { icon: 'instagram', prefix: 'fab' },
        facebook: { icon: 'facebook-f', prefix: 'fab' },
        linkedin: { icon: 'linkedin-in', prefix: 'fab' },
        tiktok: { icon: 'tiktok', prefix: 'fab' },
        twitter: { icon: 'x-twitter', prefix: 'fab' },
        email: { icon: 'envelope', prefix: 'fas' },
        site: { icon: 'globe', prefix: 'fas' },
        support: { icon: 'headset', prefix: 'fas' },
      },
    };
  },
  methods: {
    // Método para obter o nome correto do ícone
    getIconName(iconValue) {
      if (this.iconMapping[iconValue]) {
        return this.iconMapping[iconValue].icon;
      }
      return iconValue || 'globe'; // Nome padrão
    },

    // Método para obter o prefixo correto do ícone
    getIconPrefix(iconValue) {
      if (this.iconMapping[iconValue]) {
        return this.iconMapping[iconValue].prefix;
      }
      return 'fas'; // Prefixo padrão
    },

    normalizeFooterIcons(configs) {
      if (!configs || !configs.footer || !configs.footer.external_links) {
        return configs;
      }

      const normalizedLinks = configs.footer.external_links.map(link => {
        const value = link.icon || 'site';

        return {
          ...link,
          value: value,
          icon: this.getIconName(value),
          prefix: this.getIconPrefix(value),
        };
      });

      return {
        ...configs,
        footer: {
          ...configs.footer,
          external_links: normalizedLinks,
        },
      };
    },

    denormalizeFooterIcons(data) {
      if (!data || !data.external_links) {
        return data;
      }

      return {
        ...data,
        external_links: data.external_links.map(link => ({
          url: link.url,
          icon: link.value || 'site',
        })),
      };
    },
  },
};
