<template>
  <div class="m-text-area">
    <label :for="id">
      <span v-if="label" class="m-text-area-label">{{ label }}</span>
      <div class="m-text-area-wrapper" :class="[hasError ? 'm-text-area-error' : '']">
        <textarea
          :id="id"
          v-model="localValue"
          @input="updateValue"
          :maxlength="maxLength"
          :style="{ height }"
          v-bind="$attrs"
        />
      </div>
    </label>
    <span v-if="maxLength" class="m-text-area__char-counter">{{ localValue.length }}/{{ maxLength }}</span>
  </div>
</template>

<script>
export default {
  name: 'MXTextArea',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    value: {
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
  methods: {
    updateValue(event) {
      this.localValue = event.target.value;
      this.$emit('input', this.localValue);
    },
  },
};
</script>

<style scoped lang="scss">
.m-text-area {
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .m-text-area-label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .m-text-area-wrapper {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 16px;

    display: flex;
    align-items: center;

    &:focus-within {
      outline: 1px solid #3575d4;
      outline-offset: 1px;
    }

    &.m-text-area-error {
      border-color: #db3939;
    }

    textarea {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      resize: none;

      &:focus {
        outline: none !important;
      }

      &::placeholder {
        color: #6f6f6f;
      }
    }
  }
}

.m-text-area__char-counter {
  font-size: 12px;
  color: #262626;
  float: right;
}
</style>
